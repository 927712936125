export class Cookie {
    static setCookie(name, value, days, domain, sameSite = true, secure = true) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toUTCString();
        }
        else
            var expires = "";
        var domainDirective = domain ? `;domain=${domain}` : "";
        var sameSiteDirective = sameSite ? "" : ";SameSite=None";
        var secureDirective = secure ? ";Secure" : "";
        document.cookie = name + "=" + value + expires + "; path=/" + domainDirective + sameSiteDirective + secureDirective;
    }
    static readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    static storeObject(name, obj, days, domain, sameSite = true, secure = true) {
        var json = JSON.stringify(obj);
        var content = btoa(json);
        if (content.length > 4000) {
            throw `Error storing cookie ${name}: Cookie size exceeded 4kb`;
        }
        this.setCookie(name, content, days, domain, sameSite, secure);
    }
    static loadObject(name) {
        var content = this.readCookie(name);
        if (content) {
            var json = atob(content);
            var obj = JSON.parse(json);
            return obj;
        }
        else {
            return null;
        }
    }
}
