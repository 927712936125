import { FormEffects } from 'Presentation/js/connectwise/global-modules/marketo/form-effects';
import { MultistepTrialForm } from './multistep-trial-form';
export class ControlForm {
    static OnRender(form) {
        let $form = $(form.getFormElem());
        let formEffects = new FormEffects($form);
        $form.find("input[name='Phone']").each(function (i, el) {
            formEffects.addPhoneFormat($(el));
        });
        let multistepForm = new MultistepTrialForm(form);
        window.addEventListener("load", function () {
            multistepForm.initCurrentFormValues();
            setTimeout(() => {
                var i = multistepForm.getActiveFormIndex();
                if (i == 0 && multistepForm.numberOfVisibleFieldsInCurrentForm() == 0) {
                    $form.find("button[type='submit']").click();
                }
            }, 600);
        });
    }
    //fsdfds
    static async filloutDomainField(form) {
        const URL_DOMAIN_FIELD_NAME = 'siteURLControl';
        let urlfield = form.getCurrentFormInputField(URL_DOMAIN_FIELD_NAME);
        if (urlfield && !form.getDataFromAllForms[URL_DOMAIN_FIELD_NAME]) {
            let data = form.getDataFromAllForms();
            if (!data.Email) {
                throw "Email is required to suggest domain";
            }
            return $.ajax({
                type: "post",
                url: "/api/screenconnect/sugestDomain",
                data: { Email: data.Email }
            }).then((dt) => {
                var obj = {};
                obj[URL_DOMAIN_FIELD_NAME] = dt;
                form.getCurrentForm().setValues(obj);
            }, (reason) => {
                throw reason;
            });
        }
        else
            return Promise.resolve();
    }
    ;
    static OnSuccess(form) {
    }
    static async OnSubmit(form) {
        let multiStepForm = new MultistepTrialForm(form);
        multiStepForm.mergeWithState({ product: "screenconnect" });
        await ControlForm.filloutDomainField(multiStepForm);
        if (multiStepForm.isLastForm()) {
            multiStepForm.storeFormValues();
            var data = multiStepForm.getPostDataFromAllForms();
            $.ajax({
                type: "post",
                url: "/api/screenconnect/submit",
                data: data
            }).then((dt) => {
                let result = dt;
                multiStepForm.processTrialCreationResult(result);
            });
        }
        else {
            multiStepForm.submitAndGoToNextForm();
        }
    }
    static ValidationObject(form) {
        let $form = $(form.getFormElem());
        let contentId = $form.parents(".trial-form").data("contentId");
        return {
            Email: {
                emailDomainRestriction: true,
                required: true,
                email: true,
                onkeyup: false,
                async: {
                    method: function (value, element) {
                        return $.ajax({
                            url: '/api/screenconnect/validate/email',
                            type: 'post',
                            async: true,
                            data: {
                                '__RequestVerificationToken': $('[name="__RequestVerificationToken"]').val(),
                                'Email': value,
                                'contentId': contentId
                            }
                        }).then((data) => {
                            let pData = data;
                            if (pData === true)
                                return "";
                            return pData;
                        });
                    },
                    OnSubmissionOnly: false
                }
                /*
                remote: function () {
                    return {
    
                        url: window.location.pathname + '/validateEmail',
                        type: 'post',
                        async: true,
                        data: {
                            '__RequestVerificationToken': $('[name="__RequestVerificationToken"]').val(),
                            'captcha_token': ConnectWise.GlobalModules.Marketo.Captcha.GetToken($form)
                        }
                    }
                }
                */
            },
            siteURLControl: {
                required: true,
                remote: {
                    url: '/api/screenconnect/validate/domain',
                    type: 'post',
                    headers: { Accept: "application/json; charset=utf-8" },
                    async: true,
                    data: {
                        '__RequestVerificationToken': $('[name="__RequestVerificationToken"]').val()
                    }
                }
            },
            Phone: {
                required: true,
                regex: [/^\(\+[0-9]{1,3}\)[ 0-9]+$/, "Format: (+{CountryCode}) Number"]
            },
            password: {
                required: true,
                minlength: 8,
                maxlength: 64,
                regex: [/^[a-zA-Z0-9!@@#$%*()?[\]]+$/, "Valid special characters are ! @ # $ % * ( ) ? [ ]"]
            },
            passwordConfirm: {
                required: true,
                equalTo: '#password'
            }
        };
    }
    InitFormValues() {
    }
}
