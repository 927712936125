import doSearchContent from './doSearchContent';
const initSearchField = (elements, searchSettings) => {
    const { searchButton, searchInput, searchInputClear } = elements;
    if (searchButton != null) {
        searchButton.addEventListener('click', function () {
            doSearchContent(1, elements, searchSettings);
        });
    }
    if (elements.searchInput != null) {
        searchInput.addEventListener('keyup', function (event) {
            if (event.keyCode == 13) {
                doSearchContent(1, elements, searchSettings);
            }
        });
    }
    if (searchInputClear != null) {
        searchInputClear.addEventListener('click', function () {
            if (searchInput.value !== '') {
                searchInput.value = '';
                doSearchContent(1, elements, searchSettings);
            }
        });
    }
};
export default initSearchField;
