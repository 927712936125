import 'Areas/TheITNation/Presentation/scss/main.scss';
import { stickyInit } from './components/sticky';
import { carouselInit } from './components/carousel';
import { footerInit } from './components/footer';
import galleryCarouselInit from './components/galleryCarousel';
import { headerInit } from './components/header';
import { listCtaAccordionInit } from './components/list-cta';
import { mainNavigationInit } from './components/mainNavigation.js';
import productCarouselInit from './components/productCarousel';
import { productFeedsInit } from './components/productFeeds';
//import { searchPageInit } from './components/searchPage';
import { secondaryNavInit } from './components/secondaryNav';
import resellerCTAInit from './components/resellercta';
import { modalInit } from './components/modal';
import { tabcordionInit } from './components/tabcordion';
import fitvids from 'fitvids';
import { timerInit } from 'Presentation/js/components/timer';
import { bannerInit } from './components/banner';
import favoriteIconInit from './components/favoriteIcon';
import productCollectionInit from './components/product';
import { eventScheduleFormInit } from './components/eventScheduleForm';
import { tagsWithExpanderInit } from './components/tags';
import { accordionInit } from './components/accordion';
import { ControlForm } from 'Presentation/js/marketo/trial-forms/control';
import { TrustPilotHotReload } from './components/trustpilot';
import { initSearchResults } from 'Presentation/js/components/searchResults/searchResults';
import { GlobalInit } from 'Presentation/js/connectwise/global-modules/global-init';
GlobalInit();
stickyInit();
mainNavigationInit();
headerInit();
accordionInit();
listCtaAccordionInit();
carouselInit('.carousel, .herocarousel');
productFeedsInit();
footerInit();
tabcordionInit();
//searchPageInit();
modalInit();
bannerInit();
secondaryNavInit();
timerInit();
fitvids({
    players: 'iframe[src*="wistia.com"]',
});
resellerCTAInit();
galleryCarouselInit();
productCarouselInit();
tagsWithExpanderInit();
productCollectionInit();
favoriteIconInit();
eventScheduleFormInit();
initSearchResults();
globalThis.ConnectWise = require('/Presentation/js/connectwise/global');
globalThis.ConnectWise.TrustPilotHotReload = new TrustPilotHotReload();
globalThis.ConnectWise.TrialForms = { ControlForm };
