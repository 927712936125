﻿module.exports = function ($elem, seq) {
    if (seq.initialState) {
        $elem.removeClass(seq.initialState.remove)
        $elem.addClass(seq.initialState.add)
        setTimeout(() => { //next tick
            $elem.removeClass(seq.transition.remove)
            $elem.addClass(seq.transition.add)
            setTimeout(() => {
                //apply end state
                $elem.removeClass(seq.endState.remove)
                $elem.addClass(seq.endState.add)
            }, seq.duration)
        }, 40)
     }
}