/*
Prepare element by looking at parent element for data-sticky-element-id={GUID}
If not present add data-sticky-element-id={GUID} to parent element.
If not present add data-sticky-parent-id={GUID} to current element and data-sticky-element-id={GUID2}
    
*/
export function stickyInit() {
    function moveElementsToStickyContainers() {
        const stickyTop = document.querySelectorAll('.sticky-top');
        const stickyBottom = document.querySelectorAll('.sticky-bottom');
        var stop = 0;
    }
    function newGuid() {
        return Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
    }
    function moveElementsToOriginalContainers() {
    }
    function assignDataAttributes() {
        const stickyTop = document.querySelectorAll('.sticky-top');
        window["StickyTop"] = stickyTop;
        const stickyContainerTop = document.querySelector('.sticky-container-top');
        $(stickyContainerTop).attr('data-sticky-element-id', newGuid());
        if (stickyTop) {
            stickyTop.forEach((stickyTopElement) => {
                var rectangle = stickyTopElement.getBoundingClientRect();
                var parent = $(stickyTopElement).parent();
                var stickyElementId = newGuid();
                //Set the GUID on parent & child
                $(parent).attr('data-sticky-element-id', stickyElementId);
                $(stickyTopElement).attr('data-sticky-element-id', newGuid());
                $(stickyTopElement).attr('data-sticky-parent-id', stickyElementId);
                //Determine threshold
                $(stickyTopElement).attr('data-sticky-stop', rectangle.height);
                $(stickyTopElement).attr('data-sticky-start', rectangle.top);
                //Set the behavior once we hit threshold
                if (typeof $(stickyTopElement).attr('data-scroll-up-action') === 'undefined') {
                    $(stickyTopElement).attr('data-scroll-up-action', 'return'); //what to do when we scroll up past it or to its original start
                }
                if (typeof $(stickyTopElement).attr('data-scroll-down-action') === 'undefined') {
                    $(stickyTopElement).attr('data-scroll-down-action', 'return'); //what to do when we scroll down past it's original start
                }
                if (typeof $(stickyTopElement).attr('data-sticky-container-position') === 'undefined') {
                    $(stickyTopElement).attr('data-sticky-container-position', 'last'); //tells us where to place inside the sticky container start, end, number, etc
                }
            });
            document.addEventListener('scroll', function () {
                const stickyContainerTop = document.querySelector('.sticky-container-top');
                // Add a click event for each of the accordions
                stickyTop.forEach((stickyTopElement) => {
                    var rectangle = stickyTopElement.getBoundingClientRect();
                    var parent = $(stickyTopElement).parent()[0];
                    var parentId = $(parent).attr('data-sticky-element-id');
                    if (window.scrollY > stickyTopElement.scrollTop) {
                        const containerId = $(stickyContainerTop).attr('data-sticky-element-id');
                        if (parentId !== containerId) {
                            $(stickyTopElement).appendTo(stickyContainerTop);
                        }
                    }
                    else {
                        var originalParentId = $(stickyTopElement).attr('data-sticky-parent-id');
                        if (parentId !== originalParentId) {
                            var originalParent = $(document).find("[data-sticky-element-id='" + originalParentId + "']");
                            if (originalParent && parentId != originalParentId) {
                                $(stickyTopElement).appendTo(originalParent);
                            }
                        }
                    }
                });
            });
        }
    }
    window.addEventListener('load', assignDataAttributes);
    window.addEventListener('resize', moveElementsToOriginalContainers);
    window.addEventListener('resize', moveElementsToStickyContainers);
}
