import doSearchContent from './doSearchContent';
const initPagination = (elements, searchSettings) => {
    document.querySelectorAll('.js-pagination-button').forEach((button) => {
        button.addEventListener('click', (event) => {
            doSearchContent(parseInt(button.getAttribute('data-search-page')), elements, searchSettings);
            event.preventDefault();
        });
    });
};
export default initPagination;
