export class WaitFor {
    static Variable(variableName, checkIntervalMs = 2000, tries = 10) {
        let t = tries;
        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (window[variableName]) {
                    resolve(window[variableName]);
                    clearInterval(interval);
                }
                else {
                    t--;
                    if (t < 1) {
                        reject();
                        clearInterval(interval);
                    }
                }
            }, checkIntervalMs);
        });
    }
}
