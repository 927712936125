﻿module.exports =  {
    showRow: function ($elem) {
        let $formRow = $elem.hasClass("mktoFormRow") ? $elem : $elem.parents(".mktoFormRow")
        if (!$formRow.length)
            return;
        if (!$formRow.hasClass("mktoFormRowHidden") && !$formRow.hasClass("hidden") )
            return;
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
            initialState: {
                remove: "mktoFormRowHidden hidden",
                add: "collapse-fade-line-during collapse-fade-line-hidden"
            },
            duration: 1000,
            transition: {
                remove: "collapse-fade-line-hidden",
                add: "collapse-fade-line-show"
            },
            endState: {
               remove: "collapse-fade-line-show collapse-fade-line-during"
            }
        })
    },
    hideRowNoAnimations: function ($elem) {
        let $formRow = $elem.parents(".mktoFormRow");
        $formRow.addClass('mktoFormRowHidden')
    },
    isHidden: function ($elem) {
        let $formRow = $elem.parents(".mktoFormRow");
        return $formRow.hasClass('mktoFormRowHidden')
    },
    hideRow: function($elem) 
    {
        let $formRow = $elem.parents(".mktoFormRow");
        if (!$formRow.length)
            return;
        if ($formRow.hasClass("mktoFormRowHidden"))
            return;
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
                initialState: {
                    add: "collapse-fade-line-during collapse-fade-line-show"
                },
                duration: 1000,
                transition: {
                    add: "collapse-fade-line-hidden",
                    remove: "collapse-fade-line-show"
                },
                endState: {
                    add: "mktoFormRowHidden",
                    remove: "collapse-fade-line-hidden collapse-fade-line-during"
                }
        })
    },
    HasVisibleFields($form) {
        $result = $form.find(".mktoFormRow").filter(function(index)  {
            return !$(this).hasClass("mktoFormRowHidden")
        });
        return $result.length;
    },
    lockForm($form) {
        if ($form.find('.blockable-container').length == 0)
            $form.append('<div class="blockable-container"><div class="spinner"></div></div>');
    },
    unlockForm($form) {
        $form.find('.blockable-container').remove();
    }
}