/// <reference types="jquery.validation"/>
import $ from 'jquery';
export function Init(form) {
    let $form = $(form.getFormElem());
    let jQueryValue = $form.data('jqueryValidation');
    if (!jQueryValue)
        return;
    let jQueryValObject = null;
    let obj = ConnectWise.Utils.GetMethod(jQueryValue);
    if (!obj) {
        console.log('Error, jquery validation for form not found');
    }
    jQueryValObject = typeof obj == 'function' ? obj(form) : obj;
    if (!jQueryValObject)
        return;
    //make the marketo build validation not to display
    $form.addClass('disable-marketo-builtin-validation');
    $form.find(".mktoFieldWrap").append("<div class='error-placeholder'></div>");
    //add marketo required constraints to jquery validation
    let validationRules = {};
    $form.find('input.mktoRequired, select.mktoRequired').each(function (i, $el) {
        validationRules[$el.name] = { required: true };
    });
    validationRules = $.extend(true, validationRules, jQueryValObject);
    $form.validate({
        rules: validationRules,
        errorPlacement: (error, element) => {
            console.log(error, element);
            $(element).siblings(".error-placeholder").append(error);
        },
        onkeyup: function (element) {
            var element_id = jQuery(element).attr('id');
            if (this.settings.rules[element_id] && this.settings.rules[element_id].onkeyup !== false) {
                jQuery.validator.defaults.onkeyup.apply(this, arguments);
            }
        },
        onfocusout: function (element) {
            var element_id = jQuery(element).attr('id');
            if (this.settings.rules[element_id] && this.settings.rules[element_id].onkeyup === false) {
                this.element(element);
            }
            else
                jQuery.validator.defaults.onfocusout.apply(this, arguments);
        },
        highlight: function (element, errorClass) {
            $.validator.defaults.highlight.apply(this, arguments);
            $(element).parents(".mktoFormRow").addClass(errorClass);
        },
        unhighlight: function (element, errorClass) {
            $.validator.defaults.unhighlight.apply(this, arguments);
            $(element).parents('.mktoFormRow').removeClass(errorClass);
        }
    });
}
export function Enabled(form) {
    let $form = $(form.getFormElem());
    let jQueryValue = $form.data('jqueryValidation');
    return jQueryValue && true;
}
export function Validate(form) {
    let $form = $(form.getFormElem());
    let jQueryValue = $form.data('jqueryValidation');
    return new Promise((resolve, reject) => {
        if (!jQueryValue)
            resolve(); //jquery validation not enabled so it is valid
        var result = $form.valid();
        if (!result)
            reject();
        let i = 0;
        let keepTrying = function () {
            if ($form.validate().pendingRequest <= 0) {
                $form.valid() ? resolve() : reject();
            }
            else {
                i++;
                if (i < 50)
                    setTimeout(keepTrying, 100);
                else
                    reject();
            }
        };
        keepTrying();
    });
}
