export function accordionInit() {
    const accordionHeaders = document.querySelectorAll('.accordion__item_header');
    const activeClass = 'active';
    // Add a click event for each of the accordions
    accordionHeaders.forEach((accordionHeader) => {
        accordionHeader.addEventListener('click', function () {
            const accordionEl = this.parentElement;
            const isCollapsed = !accordionEl.classList.contains(activeClass);
            accordionHeaders.forEach((accordionHeader) => {
                accordionHeader.parentElement.classList.remove(activeClass);
            });
            if (isCollapsed) {
                accordionEl.classList.add(activeClass);
            }
        });
    });
}
