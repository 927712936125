import axios from 'axios';
function reloadFavoritesView() {
    const favoritesView = document.querySelector('.js-search-results-block');
    if (!favoritesView) {
        return;
    }
    const isFavorites = favoritesView.getAttribute('data-is-favorites-view');
    if (isFavorites === "true") {
        return;
    }
    if (globalThis.doSearchContent) {
        globalThis.doSearchContent(1);
    }
}
function clearEventListeners(element) {
    const newButton = element.cloneNode(true);
    element.replaceWith(newButton);
    return newButton;
}
function addClickHandler(button, handler) {
    button = clearEventListeners(button);
    button.addEventListener('click', handler);
}
function markAsFavorite(button) {
    const icon = button.querySelector('i');
    icon.classList.replace('fa-regular', 'fa-solid');
    button.classList.remove('js-add-favorite');
    button.classList.add('js-remove-favorite');
    addClickHandler(button, removeFavoriteClickHandler);
}
function markAsNotFavorite(button) {
    const icon = button.querySelector('i');
    icon.classList.replace('fa-solid', 'fa-regular');
    button.classList.remove('js-remove-favorite');
    button.classList.add('js-add-favorite');
    addClickHandler(button, addFavoriteClickHandler);
}
function getContentId(button) {
    return parseInt(button.getAttribute('data-contentid'));
}
function updateFavoriteButtons(contentId, isFavorite) {
    const favoriteButtons = document.querySelectorAll(`button[data-contentid="${contentId}"]`);
    favoriteButtons.forEach((button) => {
        if (isFavorite) {
            markAsFavorite(button);
        }
        else {
            markAsNotFavorite(button);
        }
    });
}
function removeFavoriteClickHandler(e) {
    e.preventDefault();
    const contentId = getContentId(e.currentTarget);
    axios
        .post('/api/engagementtools/RemoveFromFavorites', JSON.stringify(contentId), {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
        if (response.data.success) {
            updateFavoriteButtons(contentId, false);
            reloadFavoritesView();
        }
        else {
            console.error(`Failed to remove content item from favorites [contentId=${contentId}]`, response);
        }
    })
        .catch(function (error) {
        console.error(`Failed to remove content item from favorites [contentId=${contentId}]`, error);
    });
}
function addFavoriteClickHandler(e) {
    e.preventDefault();
    const contentId = getContentId(e.currentTarget);
    axios
        .post('/api/engagementtools/AddToFavorites', JSON.stringify(contentId), {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
        if (response.data.success) {
            updateFavoriteButtons(contentId, true);
            reloadFavoritesView();
        }
        else {
            console.error(`Failed to add content item to favorites [contentId=${contentId}]`, response);
        }
    })
        .catch(function (error) {
        console.error(`Failed to add content item to favorites [contentId=${contentId}]`, error);
    });
}
export default function favoriteIconInit() {
    const addFavoriteButtons = document.querySelectorAll('button.js-add-favorite');
    const removeFavoriteButtons = document.querySelectorAll('button.js-remove-favorite');
    addFavoriteButtons.forEach((button) => {
        addClickHandler(button, addFavoriteClickHandler);
    });
    removeFavoriteButtons.forEach((button) => {
        addClickHandler(button, removeFavoriteClickHandler);
    });
}
