﻿const zoomInfoMapping = require('./zoom-info-marketo-mapping')
module.exports = function () {

    var ZoomInfoFields = ['Company', 'Phone', 'FirstName', 'LastName','mKTOIndustry'];
    function AddClassToZoomInfoFields($form) {
        $form.find('input, select').each(function (index, field) {
            $input = $(field);
            if (ZoomInfoFields.find(el => el === $input[0].name)) {
                $input
                    .parents(".mktoFormRow")
                    .addClass("zoom-info-field")
                    .addClass("mktoFormRowHidden");
            } 
        });

    }

   

    function showRow($formRow) {
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
            initialState: {
                remove: "mktoFormRowHidden",
                add: "collapse-fade-line-during collapse-fade-line-hidden"
            },
            duration: 1000,
            transition: {
                remove: "collapse-fade-line-hidden",
                add: "collapse-fade-line-show"
            },
            endState: {
                add: "zoom-info-match-not-found",
                remove:"collapse-fade-line-show collapse-fade-line-during"
            }
        })
    }

    function hideRow($formRow) {
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
            initialState: {
                remove: "zoom-info-match-not-found",
                add: "collapse-fade-line-during collapse-fade-line-show"
            },
            duration: 1000,
            transition: {
                add: "collapse-fade-line-hidden",
                remove: "collapse-fade-line-show"
            },
            endState: {
                add: "mktoFormRowHidden",
                remove: "collapse-fade-line-hidden collapse-fade-line-during"
            }
        })
    }

    function IsZoomEnabled(form) {
        let $form = $(form.getFormElem());
        var ziId = $form.data("zoomInfo");
        if (!ziId) {
            //zoom id not found in the form, maybe it is set on its root form
            var formId = $form.data("rootForm"); //get the root form
            if (formId && $(`#${formId}`).data("zoomInfo")) {
                return true;
            }
            return false;
        }
        return true;
    }

    function Valid(form) {
        let $form = $(form.getFormElem());
        var ziId = $form.data("zoomInfo");
        if (!ziId) {
            return true; //isd valid because zoom info is not enabled
        }
        //If any zoominfo field is invalid make sure to display it 
        $form
            .find(".zoom-info-field .mktoInvalid")
            .parents(".zoom-info-field")
            .each((index, field) => {
                $fd = $(field);
                showRow($fd)
            })
        if (neverBounceValid($form)) {
            return true;
        } else {
            form.showErrorMessage("Email invalid or disposable", form.getFormElem().find("#Email"));
            return false;
        }
    }

    function Init(form) {
        let $form=$(form.getFormElem());
        var ziId = $form.data("zoomInfo");
        if (!ziId) {
            //zoom id not found in the form, maybe it is set on its root form
            var formId = $form.data("rootForm"); //get the root form
            if (formId && $(`#${formId}`).data("zoomInfo")) {
                AddClassToZoomInfoFields($form)
            }
            return;
        }
        AddClassToZoomInfoFields($form);
        var tm = 0;
        $form.attr("data-zoom-bind", ziId);
        $email = $form.find("input[name='Email']").on('click', function () {
            $(this).val("")
        });

      
        $email.val("")
  
        var setZoomFieldsToNotMatched = function () {
            $(".zoom-info-field").each((index, field) => {
                $fd = $(field)
                showRow($fd);
            })
        }

        //if zoominfo fails to load in 4secs, display all the fields.
        var tmStart = setTimeout(setZoomFieldsToNotMatched, 4000);
        var zoomDebug = ConnectWise.Utils.GetUrlParameters()['zoominfodebug'];
        window._zi = {
            formId: ziId,
            development: zoomDebug && zoomDebug === 'true' ? true : false,
            formLoadTimeout: 4000,
            callbacks: {
                onRequestSent: function (email) {
                },
                onReady: function () {
                    //map loaded, cancel timeout
                    clearTimeout(tmStart);
                },
                onMatch: function (data) {
                    //for all zoom info fields in the page
                    $(".zoom-info-field").each((index, field) => {
                        $fd = $(field)
                        var $i = $fd.find("input, select");


                        if ($i.length) { //field found ?
                            var fieldName = $i[0].name; //get marketo field name
                            if (data[fieldName]) { //has zoom info data
                                
                                hideRow($fd);
                               
                                
                                if (zoomInfoMapping[fieldName]) { //has value mapping for the marketo field
                                    var marketoValue = zoomInfoMapping[fieldName][data[fieldName]] ?
                                        zoomInfoMapping[fieldName][data[fieldName]].MarketoMapping : zoomInfoMapping[fieldName].Default;
                                    $i.val(marketoValue);
                                }
                                else if (fieldName == "Phone") {
                                    $i.val(data[fieldName].replaceAll(".", ""))
                                }
                                else {
                                    $i.val(data[fieldName])
                                }
                            } else {
                                showRow($fd)
                            }
                        }
                    })
                }
            }
                    };
        var zi = document.createElement('script');
        zi.type = 'text/javascript';
        zi.async = true;
        zi.src = 'https://ws-assets.zoominfo.com/formcomplete.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(zi, s);

    }

    function Enabled(form) {
        let $form = $(form.getFormElem());
        var ziId = $form.data("zoomInfo");
        return !!ziId

    }
 

    function neverBounceValid($form) {
        
       
        var $email = $form.find("input[name='Email']");
        var email = $email.val();
        var neverBounceStatus = $email.attr("data-zi-neverbounce-status")
        if (email && email.endsWith("@thisisjust.atomatest.com")) {
            neverBounceStatus = "valid"
        }
        
        console.log("neverbounce: " + neverBounceStatus)
        var invalid = neverBounceStatus && (neverBounceStatus == "disposable" || neverBounceStatus == "invalid")
        return !invalid;
    }
    
 
    //Add never bounce validation
   
        jQuery.validator.addMethod("neverbounce", function (value, element, param, method) {
            
            if (!param)
                return;
            var $form = $(element).parents("form");
            if ($form.data("submissionStarted")) {
                return neverBounceValid($form);
            }
            else {
                return true;
            }
        }, "Email invalid or disposable");
    

    return {
        Init,
        ZoomInfoFields,
        Valid
      

    }


}();