export class TrustPilotHotReload {
    static TrustPilotHotReloadIsScheduled = false;
    static Run() {
        window["TrustPilotHotReloadIsScheduled"];
        if (TrustPilotHotReload.TrustPilotHotReloadIsScheduled) {
            return;
        }
        else {
            if (!window.Trustpilot)
                return;
            TrustPilotHotReload.TrustPilotHotReloadIsScheduled = true;
            setTimeout(() => {
                $(".trustpilot-widget").each((i, el) => {
                    if (!TrustPilotHotReload.isTrustPilotLoaded(el)) {
                        window.Trustpilot.loadFromElement(el);
                    }
                });
                TrustPilotHotReload.TrustPilotHotReloadIsScheduled = false;
            }, 0);
        }
    }
    static isTrustPilotLoaded(el) {
        return $(el).find("iframe").length > 0;
    }
}
