import { WaitFor } from "Presentation/js/connectwise/utils/waitfor";
import { FormEffects } from "Presentation/js/connectwise/global-modules/marketo/form-effects";
export class Captcha {
    form;
    Enabled;
    constructor(form) {
        this.form = form;
    }
    Init() {
        let $form = $(this.form.getFormElem());
        let _form = new FormEffects($form);
        let key = $form.data("captcha");
        let tech = $form.data("captchaTech");
        let Enabled = key && tech && tech !== "NONE";
        if (this.isEnabled()) {
            $("<div class='mktoFormRow'><div class='mktoCaptcha'></div></div>").insertBefore($form.find(".mktoButtonRow"));
            let $captcha = $form.find(".mktoCaptcha");
            if (tech === "TURNSTILE") {
                WaitFor.Variable("turnstile", 2000, 10).then(() => {
                    let widgetId = window.turnstile.render($captcha[0], {
                        sitekey: key,
                        size: "flexible",
                        callback: (token) => {
                            console.log(`Challenge Success ${token}`);
                            $captcha.data({ token });
                            _form.enableButton();
                            this.clearError();
                        },
                        "expired-callback": () => {
                            _form.disableButton();
                        }
                    });
                    $captcha.data("widgetId", widgetId);
                });
            }
            else if (tech === "RECAPTCHA") {
                WaitFor.Variable("grecaptcha", 2000, 10).then(() => {
                    let widgetId = globalThis.grecaptcha.render($captcha[0], {
                        sitekey: key,
                        theme: "light",
                        // size: "normal",
                        callback: (token) => {
                            console.log(`Challenge Success ${token}`);
                            $captcha.data({ token });
                            _form.enableButton();
                            this.clearError();
                        },
                        "expired-callback": () => {
                            _form.disableButton();
                        },
                        "error-callback": function () {
                            console.log("Error happened, cant run captcha");
                        }
                    });
                    $captcha.data("widgetId", widgetId);
                });
            }
        }
    }
    getCaptchaElement() {
        let $form = $(this.form.getFormElem());
        return $form.find(".mktoCaptcha");
    }
    clearError() {
        this.getCaptchaElement().removeClass("error");
    }
    setError() {
        this.getCaptchaElement().addClass("error");
    }
    isEnabled() {
        let $form = $(this.form.getFormElem());
        let key = $form.data("captcha");
        let tech = $form.data("captchaTech");
        return key && tech && tech !== "NONE";
    }
    async valid() {
        if (!this.isEnabled())
            return true;
        let $form = $(this.form.getFormElem());
        let form = new FormEffects($form);
        let email = form.getEmail();
        let token = this.getCaptchaElement().data("token");
        if (!token) {
            this.setError();
            return false;
        }
        if (form.getValidationToken()) {
            return true;
        }
        return $.ajax({
            type: 'POST',
            url: "/api/captcha/validate",
            data: {
                email,
                token,
                contentId: $form.data("contentId"),
                __RequestVerificationToken: $('[name="__RequestVerificationToken"]').val()
            },
            success: (token) => {
                this.clearError();
                form.setValidationToken(token);
                return true;
            },
            error: (data) => {
                this.setError();
                return false;
            }
        });
    }
}
;
