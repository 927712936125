export function bannerInit() {
    const banner = document.querySelectorAll('.banner');
    const header = document.querySelector('#PageHeader');
    const main = document.querySelector('#PageMain');
    const hero = document.querySelector('[data-hero]');
    const footer = document.getElementById('PageFooter');
    const windowWidth = window.innerWidth;
    const topAlert = document.querySelectorAll('.top-alert');
    const closeBanner = document.getElementById('close-banner');
    banner.forEach(function (item) {
        if (windowWidth < 656) {
            if (item.classList.contains('stick-bottom-Mobile')) {
                item.classList.add('hide-on-load');
            }
        }
    });
    /**
     * Calculate header height based on sticky banners on top
     * and add it either main block or hero block whichever is available below header
     * */
    function calculateHeaderHeight(headerHeight) {
        if (main && hero === null) {
            main.style.marginTop = `${headerHeight}px`;
        }
        else {
            hero.style.marginTop = `${headerHeight}px`;
        }
        if (main && hero == null) {
            main.style.marginTop = `${headerHeight}px`;
        }
        else {
            hero.style.marginTop = `${headerHeight}px`;
        }
    }
    /**
     * Based on whether sticky bottom or top is selected, banner will be positioned
     */
    window.addEventListener('load', function () {
        banner.forEach(function (item) {
            if (item.classList.contains('stick-top-desktop')) {
                header.appendChild(item);
                const headerHeight = header.clientHeight;
                calculateHeaderHeight(headerHeight);
            }
            if (item.classList.contains('stick-bottom-Mobile') && windowWidth < 656) {
                item.classList.add('hide-on-load');
            }
        });
    });
    /**
     * shows or hides the bottom banner based on scroll position
     */
    window.addEventListener('scroll', function () {
        const mainTop = main.getBoundingClientRect().top;
        banner.forEach((b) => {
            const bannerHeight = b.getBoundingClientRect().height;
            if (mainTop < 0 && b.classList.contains('stick-bottom-Mobile') && windowWidth < 656) {
                b.classList.remove('hide-on-load');
                footer.style.marginBottom = `${bannerHeight}px`;
            }
            else if (mainTop >= 0 &&
                !b.classList.contains('hide-on-load') &&
                b.classList.contains('stick-bottom-Mobile') &&
                windowWidth < 656) {
                b.classList.add('hide-on-load');
            }
        });
    });
    /**
     * Function that closes sticky banner
     */
    topAlert.forEach((a) => {
        a.addEventListener('click', () => {
            a.parentElement.classList.add('close-alert');
            const headerHeight = header.clientHeight;
            calculateHeaderHeight(headerHeight);
        });
    });
    if (closeBanner) {
        closeBanner.addEventListener('click', () => {
            banner.forEach((b) => {
                if (b.classList.contains('stick-bottom-Mobile')) {
                    b.classList.add('close-alert');
                }
            });
        });
    }
}
