export function secondaryNavInit() {
    const listCTAs = document.querySelectorAll('#secondary-nav .list-cta.desktop .heading-title');
    listCTAs.forEach((cta) => cta.insertAdjacentHTML('beforeend', '<i class="fa-solid fa-chevron-down margin-left"></i>'));
    const handleSecondaryMenuToggle = (event) => {
        const el = event.target;
        const menuItems = document.querySelectorAll('#secondary-nav .list-cta.desktop');
        // Menu action
        menuItems.forEach((menu) => {
            if (menu !== null && (el.parentElement !== menu || el.parentElement.parentElement !== menu) && menu.classList.contains('show-menu')) {
                menu.classList.remove('show-menu');
            }
            else if (menu !== null &&
                (el.parentElement === menu || el.parentElement.parentElement === menu) &&
                menu.classList.contains('show-menu')) {
                menu.classList.toggle('show-menu');
            }
            else if (menu !== null &&
                (el.parentElement === menu || el.parentElement.parentElement === menu) &&
                !menu.classList.contains('show-menu')) {
                menu.classList.add('show-menu');
            }
        });
    };
    const secondaryMenuBar = document.querySelector('#secondary-menu-bar');
    if (!secondaryMenuBar)
        return;
    secondaryMenuBar.addEventListener('click', () => {
        const secondaryMenuItems = document.querySelector('#secondary-menu-items');
        const secondaryMenuIcons = document.querySelector('#secondary-menu-togglebutton');
        secondaryMenuItems.classList.toggle('expand-menu');
        secondaryMenuIcons.classList.toggle('hide-icon');
    }, false);
    document.addEventListener('click', handleSecondaryMenuToggle, false);
}
