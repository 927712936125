import { MultisiteCookie } from './multisite-cookie';
import { Utils } from 'Presentation/js/utils';
export class UTMHandler {
    static Init() {
        var utm = Utils.GetQueryParams();
        if (utm.utm_medium || utm.utm_campaign || utm.utm_source || utm.utm_content || utm.utm_term) {
            utm.utm_updated = new Date().getTime();
            let utmWithAllFields = UTMHandler.MergeUtmIntoObject({}, utm);
            MultisiteCookie.MergeInCookieObject(utmWithAllFields);
        }
        UTMHandler.AddMultisiteCookieListener();
    }
    static AddMultisiteCookieListener() {
        MultisiteCookie.OnSharedCookieSet((event) => {
            var obj = Utils.GetQueryParams();
            //url parameters present, override shared cookie
            if (obj.utm_medium || obj.utm_campaign || obj.utm_source || obj.utm_content || obj.utm_term) {
                obj.utm_updated = new Date().getTime();
                UTMHandler.MergeUtmIntoObject(event.response, obj);
                return;
            }
            if (event.preveous &&
                event.new &&
                event.preveous.utm_updated &&
                event.new.utm_updated &&
                event.preveous.utm_updated > event.new.utm_updated) {
                UTMHandler.MergeUtmIntoObject(event.response, event.preveous);
            }
            else {
                UTMHandler.MergeUtmIntoObject(event.response, event.new);
            }
        });
    }
    static GetUtmParametersFromSharedCookie() {
        let obj = (MultisiteCookie.GetMultisiteCookieObject() || {});
        return {
            utm_source: obj.utm_source,
            utm_campaign: obj.utm_campaign,
            utm_medium: obj.utm_medium,
            utm_content: obj.utm_content,
            utm_term: obj.utm_term
        };
    }
    static MergeUtmIntoObject(target, utm) {
        target.utm_source = utm.utm_source || "";
        target.utm_campaign = utm.utm_campaign || "";
        target.utm_medium = utm.utm_medium || "";
        target.utm_content = utm.utm_content || "";
        target.utm_term = utm.utm_term || "";
        target.utm_updated = utm.utm_updated || 0;
        return target;
    }
}
