﻿

module.exports = {
    DefaultValidationMessage: "Please use your business email",

    Validate: function (form) {
        return function (isFormValid) {

            if (!isFormValid) {
                return;
            }

            // Check if the email domain is in the restricted domain list
            const action = form.getFormElem().data('restricted-domain-action');
            if (!action) {
                return;
            }

            const message = module.exports.ValidateEmailDomains(form.getFormElem(), form.vals().Email);

            if (message) {
                var emailElem = form.getFormElem().find("#Email");

                if (action === "error") {
                    form.showErrorMessage(message, emailElem);
                    form.submittable(false);
                }
            } else {
                form.submittable(true);
            }
        };
    },

    GetValidationMessage: function($form) {
        var message = $form.data('restricted-domain-message');

        if (!message) {
            message = module.exports.DefaultValidationMessage;
        }
        return message;
    },

    ValidateEmailDomains: function (form, emailFieldValue) {

        const excludedDomains = form.data('restricted-domains');

        emailFieldValue = emailFieldValue.trim().toLowerCase();
        var passedValidation = true;

        if (excludedDomains) {
            excludedDomains.split(',').forEach(function (item) {
                if (item && item.length) {
                    item = item.trim().toLowerCase();
                    if (item.charAt(0) !== '@') {
                        item = '@' + item;
                    }
                    if (emailFieldValue.endsWith(item)) {
                        passedValidation = false;
                    }
                }
            });
        }

        if (!passedValidation) {
            return module.exports.GetValidationMessage(form);
        } else {
            return false;
        }
    },

    RegisterEmailFieldWarning: function (form) {
        let $formElem = form.getFormElem();
        if (!$formElem)
            return;

        // Add a warning message below the email field
        if ($formElem.data('restricted-domain-action') === 'warning') {
            var $emailField = $formElem.find('input#Email, input#emailAddress');
            $emailField.blur(function () {
                const message = module.exports.ValidateEmailDomains($formElem, $emailField.val());
                if (message) {
                    if (!$('#email-warning-message').length) {
                        $emailField.parent().append('<div id="email-warning-message" class="field-message">' + message + '</div>');
                        $emailField.addClass('warning');
                    }
                } else {
                    $emailField.removeClass('warning');
                    $('#email-warning-message').remove();
                }
            });
        }
    }
};


