export class FormEffects {
    $form;
    constructor($form) {
        this.$form = $form;
    }
    showRow($elem) {
        let $formRow = $elem.hasClass('mktoFormRow') ? $elem : $elem.parents('.mktoFormRow');
        if (!$formRow.length)
            return;
        if (!$formRow.hasClass('mktoFormRowHidden') && !$formRow.hasClass('hidden'))
            return;
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
            initialState: {
                remove: 'mktoFormRowHidden hidden',
                add: 'collapse-fade-line-during collapse-fade-line-hidden',
            },
            duration: 1000,
            transition: {
                remove: 'collapse-fade-line-hidden',
                add: 'collapse-fade-line-show',
            },
            endState: {
                remove: 'collapse-fade-line-show collapse-fade-line-during',
            },
        });
    }
    hideRowNoAnimations($elem) {
        let $formRow = $elem.parents('.mktoFormRow');
        $formRow.addClass('mktoFormRowHidden');
    }
    getFieldVal(name) {
        return this.$form.find(`input[name="${name}"]`).val().toString();
    }
    getEmail() { return this.getFieldVal("Email"); }
    setValidationToken(token) {
        if (token) {
            if (!this.$form.find(`input[name="serverValidationToken"]`).length) {
                this.$form.append(`<input type='hidden' name='serverValidationToken' value="${token}"/>");`);
            }
            else {
                this.$form.find(`input[name="serverValidationToken"]`).val(token);
            }
        }
    }
    getValidationToken() {
        var result = this.$form.find(`input[name="server-token-validation"]`).val();
        return result;
    }
    isHidden($elem) {
        let $formRow = $elem.parents('.mktoFormRow');
        return $formRow.hasClass('mktoFormRowHidden');
    }
    hideRow($elem) {
        let $formRow = $elem.parents('.mktoFormRow');
        if (!$formRow.length)
            return;
        if ($formRow.hasClass('mktoFormRowHidden'))
            return;
        ConnectWise.GlobalModules.Effects.CssTimeSequence($formRow, {
            initialState: {
                add: 'collapse-fade-line-during collapse-fade-line-show',
            },
            duration: 1000,
            transition: {
                add: 'collapse-fade-line-hidden',
                remove: 'collapse-fade-line-show',
            },
            endState: {
                add: 'mktoFormRowHidden',
                remove: 'collapse-fade-line-hidden collapse-fade-line-during',
            },
        });
    }
    disableButton() {
        $(this.$form).find('.mktoButton').prop('disabled', true);
    }
    enableButton() {
        $(this.$form).find('.mktoButton').prop('disabled', false);
    }
    HasVisibleFields() {
        let $result = this.$form.find('.mktoFormRow').filter(function (index) {
            return !$(this).hasClass('mktoFormRowHidden');
        });
        return $result.length;
    }
    lockForm() {
        if (this.$form.find('.blockable-container').length == 0)
            this.$form.append('\
      <div class="blockable-container">\
      <div class="loader">\
        <span></span>\
        <span></span>\
        <span></span>\
       </div></div>');
    }
    unlockForm() {
        this.$form.find('.blockable-container').remove();
    }
    addSuffix($input, suffix) {
        $input.data('suffix', suffix);
        let [oldValue, oldStart, oldEnd] = [suffix, 0, 0];
        $input.on('focusin', (ev) => {
            let $el = $(ev.target);
            if (!$el.val().endsWith(suffix)) {
                $el.val($el.val() + suffix);
                $el[0].setSelectionRange(0, 0);
            }
        });
        $input.on('focusout', (ev) => {
            let $el = $(ev.target);
            if ($el.val() == suffix) {
                $el.val('');
            }
        });
        $input.on('mouseup touchend', (ev) => {
            let $el = $(ev.target);
            let suffixPos = $el.val().indexOf(suffix);
            if (suffixPos > -1) {
                $el[0].setSelectionRange(Math.min($el[0].selectionStart, suffixPos), Math.min($el[0].selectionEnd, suffixPos));
            }
        });
        $input.on('keydown', (ev) => {
            let $el = $(ev.target);
            oldValue = ev.target.value;
            oldStart = ev.target.selectionStart;
            oldEnd = ev.target.selectionEnd;
            let suffixPos = $el.val().indexOf(suffix);
            if (suffixPos > -1) {
                if (suffixPos == $el[0].selectionStart || suffixPos == $el[0].selectionEnd) {
                    if (ev.code == 'ArrowRight') {
                        ev.preventDefault();
                        return;
                    }
                }
                $el[0].setSelectionRange(Math.min($el[0].selectionStart, suffixPos), Math.min($el[0].selectionEnd, suffixPos));
            }
        });
        $input.on('input', (ev) => {
            let $el = $(ev.target);
            if (!$el.val().endsWith(suffix)) {
                $el.val(oldValue);
                ev.target.setSelectionRange(oldStart, oldEnd);
            }
        });
    }
    addPhoneFormat($input) {
        let initialValue = `(+${window.geoPhoneCountryCode || 1}) `;
        $input.on('focusout', (ev) => {
            let $el = $(ev.target);
            if ($el.val() == initialValue) {
                $el.val('');
            }
        });
        $input.on('focusin', (ev) => {
            let $el = $(ev.target);
            if (!($el.val() || '').trim()) {
                $el.val(initialValue);
            }
        });
        $input.on('keypress', (ev) => {
            if (ev.key && !(ev.key || '').match(/^[ 0-9]$/)) {
                ev.preventDefault();
                return false;
            }
        });
        $input.on('keydown', (ev) => {
            let $el = $(ev.target);
            let oldValue = ev.target.value;
            if (oldValue.match(/^\(\+[0-9]{0,3}\)[ 0-9]+$/))
                $el.data('oldValue', oldValue);
        });
        $input.on('keyup', (ev) => {
            let $el = $(ev.target);
            let oldValue = $el.data('oldValue');
            var value = $el.val() || '';
            var cursorPos = $el[0].selectionStart;
            if (!value.match(/^\(\+[0-9]{0,3}\)[ 0-9]+$/)) {
                $el.val(oldValue || initialValue);
                $el[0].setSelectionRange(cursorPos, cursorPos, 'forward');
            }
            else {
                if (value.match(/^\(\+[0-9]{0,3}\)[0-9]/)) {
                    $el.val(value.replace(')', ') '));
                    $el[0].setSelectionRange(cursorPos, cursorPos, 'forward');
                }
            }
        });
    }
}
;
