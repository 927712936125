export function productFeedsInit() {
    const productFeedWrappers = document.querySelectorAll('.product-feed-wrapper');
    productFeedWrappers.forEach((productFeedWrapper) => {
        const problemSelection = productFeedWrapper.querySelector('.js-problem-selection');
        const resetFilterButton = productFeedWrapper.querySelector('.js-reset-filter');
        const productFeedItems = productFeedWrapper.querySelector('.js-product-feed-items');
        const getProducts = async () => {
            var xhttp = new XMLHttpRequest();
            const productFeedAPIUrl = '/api/search/product-feeds/';
            const productFeedRef = productFeedWrapper.getAttribute('data-block-id');
            const currentPageRef = productFeedWrapper.getAttribute('data-current-page-id');
            var apiRequestUrl = `${productFeedAPIUrl}?productFeedRef=${productFeedRef}&problem=${problemSelection.value}&currentPageRef=${currentPageRef}`;
            xhttp.open('GET', apiRequestUrl, false);
            xhttp.send();
            productFeedItems.innerHTML = xhttp.responseText;
            registerSeeMoreOrLessEvent();
        };
        const registerSeeMoreOrLessEvent = () => {
            if (!productFeedItems)
                return;
            productFeedItems.querySelectorAll('.js-product-feed-item').forEach((feedItem) => {
                const itemHeader = feedItem.querySelector('.item-header');
                const itemBody = feedItem.querySelector('.item-body');
                itemHeader.addEventListener('click', (event) => {
                    itemHeader.classList.toggle('active');
                    itemBody.classList.toggle('active');
                    itemHeader.innerHTML = itemHeader.classList.contains('active') ? 'Hide details' : 'Show details';
                });
            });
        };
        registerSeeMoreOrLessEvent();
        problemSelection.addEventListener('change', (event) => {
            getProducts();
        });
        resetFilterButton.addEventListener('click', (event) => {
            problemSelection.value = '';
            getProducts();
        });
    });
    function selectIconState(event) {
        const select = document.querySelector('.js-problem-selection');
        const selectParent = select && select.parentElement;
        if (!select)
            return;
        if (event.target !== select) {
            selectParent.classList.remove('show');
        }
        else {
            select.parentElement.classList.toggle('show');
        }
    }
    document.addEventListener('click', selectIconState);
}
