import { Swiper } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
const productCarouselSwiperBreakpoints = {
    // $md-min: 41rem;
    960: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        centeredSlides: false,
    },
    1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        centeredSlides: false,
    },
    1700: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        centeredSlides: false,
    },
};
const productCarouselSwiperOptions = {
    loop: false,
    modules: [Navigation, Pagination],
    spaceBetween: 16,
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: true,
    breakpoints: productCarouselSwiperBreakpoints,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    watchOverflow: true,
};
export default function productCarouselInit() {
    let carouselElements = document.querySelectorAll('.product-carousel');
    if (!carouselElements)
        return;
    carouselElements.forEach((element) => {
        const carouselId = element.getAttribute('data-carouselid');
        const carousel = new Swiper(element, {
            ...productCarouselSwiperOptions,
            navigation: {
                nextEl: `.swiper-button-next[data-carouselid="${carouselId}"]`,
                prevEl: `.swiper-button-prev[data-carouselid="${carouselId}"]`,
            },
        });
    });
}
