export const insertQueryParamToCurrentUrl = function (queryString, key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);
    var kvp = queryString.match(/[^&]+/g) ?? [];
    let i = 0;
    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }
    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=');
    }
    return kvp.join('&');
};
