export function modalInit() {
    const modalButtons = document.querySelectorAll('.js-modal-button');
    modalButtons.forEach((element) => {
        element.addEventListener('click', (event) => {
            const modalId = element.getAttribute('data-modal-id');
            const modalTemplate = document.getElementById(`${modalId}-modal-template`);
            if (modalTemplate == null)
                return;
            const modalContainer = modalTemplate.children.item(0).cloneNode(true);
            if (modalContainer) {
                modalContainer.id = `${modalId}`;
                const closeModalButton = modalContainer.querySelector('.js-close-button');
                if (closeModalButton) {
                    closeModalButton.addEventListener('click', (event) => {
                        const innerModalContainer = document.getElementById(`${closeModalButton.getAttribute('data-modal-id')}`);
                        if (innerModalContainer)
                            document.body.removeChild(innerModalContainer);
                    });
                }
            }
            document.body.appendChild(modalContainer);
        });
    });
}
