﻿module.exports = function() {

    function isConnectWiseDomain(domain) {
        return domain && domain.endsWith(".connectwise.com");
    }

    return {
        storeExternalDomainRef: function () {
            
            var ref = window.document.referrer;
            var current = window.document.location.href;
            if (ref && current) {
                var refUrl = new URL(ref);
                var currentUrl = new URL(current);
                if (!refUrl.hostname)
                    return;
                var refCache = ConnectWise.Utils.Cookie.loadObject("__referenceDomain") || {};

                if (refUrl.hostname == currentUrl.hostname) {
                    //sessionStorage doesn't have data expiration, so use the information in the cookie.
                    //if the cookie expires, invalidate the data on the session storage too. 
                    if (!refCache[refUrl.hostname]) {
                        sessionStorage.setItem("__referenceDomain", null)
                    }

                    if (!sessionStorage.getItem("__referenceDomain") && refCache[refUrl.hostname]) {
                        sessionStorage.setItem("__referenceDomain", refCache[refUrl.hostname])
                    }
                }
                else if (isConnectWiseDomain(refUrl.hostname))
                {
                    if (refCache[refUrl.hostname]) {
                        refCache[currentUrl.hostname] = refCache[refUrl.hostname];
                        let cookieDomain = isConnectWiseDomain(currentUrl.hostname) ? ".connectwise.com" : "";
                        ConnectWise.Utils.Cookie.storeObject("__referenceDomain", refCache, 1/24 , cookieDomain);
                        sessionStorage.setItem("__referenceDomain", refCache[currentUrl.hostname]);
                    }
                }
                else
                {
                    var refCache = ConnectWise.Utils.Cookie.loadObject("__referenceDomain") || {};
                    refCache[currentUrl.hostname] = refUrl.toString();
                    let cookieDomain = isConnectWiseDomain(currentUrl.hostname) ? ".connectwise.com" : "";
                    ConnectWise.Utils.Cookie.storeObject("__referenceDomain", refCache, 1/24, cookieDomain);
                    sessionStorage.setItem("__referenceDomain", refCache[currentUrl.hostname]);

                }
            }
        },
        getExternalDomain: function () {

            return sessionStorage.getItem("__referenceDomain") || null;
  
        }

    }
}();