const openDrawer = () => {
    $('.pd-resellercta-drawer').addClass('open');
    $('html').addClass('drawer-open');
};
const closeDrawer = () => {
    $('.pd-resellercta-drawer').removeClass('open');
    $('html').removeClass('drawer-open');
};
export default function resellerCTAInit() {
    $('.pd-resellercta button.js-modal-button[data-modal-id="resellercta-drawer"]').on('click', function () {
        openDrawer();
    });
    $('.pd-resellercta-drawer-closebutton').on('click', function () {
        closeDrawer();
    });
}
