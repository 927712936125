﻿module.exports = function () {
    var result = {};
    if (location.search)
        location.search.substr(1).split("&").forEach(function (item) {
            var s = item.split("=");
            if (s.length > 1) {
                var k = s[0];
                var v = s[1];
                decodeURIComponent(s[1]);
                (result[k] = v)
            }
        })
    return result;

};