import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
function getCarouselSwiperBreakpoints(slidesPerViewMobile, slidesPerViewDesktop) {
    return {
        // $md-min: 41rem;
        656: {
            slidesPerView: Math.min(Math.max(2, slidesPerViewMobile), slidesPerViewDesktop),
            slidesPerGroup: Math.min(Math.max(2, slidesPerViewMobile), slidesPerViewDesktop),
            centeredSlides: false,
        },
        // $lg-min: 64rem
        1024: {
            slidesPerView: Math.min(Math.max(3, slidesPerViewMobile), slidesPerViewDesktop),
            slidesPerGroup: Math.min(Math.max(3, slidesPerViewMobile), slidesPerViewDesktop),
            centeredSlides: false,
        },
        // $xl-min: 90rem
        1440: {
            slidesPerView: slidesPerViewDesktop,
            slidesPerGroup: slidesPerViewDesktop,
            centeredSlides: false,
        },
    };
}
export function carouselInit(selector) {
    let carouselElements = document.querySelectorAll(selector);
    if (!carouselElements)
        return;
    carouselElements.forEach((element) => {
        const isInfinite = element.getAttribute('data-inifite-loop') === 'enabled';
        const slidesPerViewMobile = parseInt(element.getAttribute('data-slides-per-view-mobile'));
        const slidesPerViewDesktop = parseInt(element.getAttribute('data-slides-per-view-desktop'));
        const paginationElement = element.querySelector('.swiper-pagination');
        const paginationColorClass = paginationElement?.getAttribute('data-color-class');
        const breakpoints = getCarouselSwiperBreakpoints(slidesPerViewMobile, slidesPerViewDesktop);
        const paginationOptions = {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + (paginationColorClass ? ` ${paginationColorClass}` : '') + '"></span>';
            },
        };
        console.log('carouselInit', {
            selector,
            isInfinite,
            slidesPerViewMobile,
            slidesPerViewDesktop,
            breakpoints,
            paginationColorClass,
        });
        try {
            const carousel = new Swiper(element, {
                modules: [Navigation, Pagination],
                loop: isInfinite,
                slidesPerView: slidesPerViewMobile,
                centeredSlides: slidesPerViewMobile === 1,
                pagination: paginationOptions,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints,
            });
        }
        catch (e) {
            console.error('Error initializing carousel', e);
        }
    });
}
