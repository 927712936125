﻿module.exports = {
    mKTOIndustry: {

        Default: "Internal IT",

        "Agriculture": {

            "Id": "agriculture",
            "MarketoMapping": "Internal IT"

        },
        "Animals & Livestock": {

            "Id": "agriculture.animals",
            "MarketoMapping": "Internal IT"

        },
        "Crops": {

            "Id": "agriculture.crops",
            "MarketoMapping": "Internal IT"

        },
        "Forestry": {

            "Id": "agriculture.forestry",
            "MarketoMapping": "Internal IT"

        },
        "Business Services": {

            "Id": "bizservice",
            "MarketoMapping": "Internal IT"

        },
        "Accounting Services": {

            "Id": "bizservice.accounting",
            "MarketoMapping": "Internal IT"

        },
        "Call Centers & Business Centers": {

            "Id": "bizservice.callcenter",
            "MarketoMapping": "Internal IT"

        },
        "Chambers of Commerce": {

            "Id": "bizservice.chamber",
            "MarketoMapping": "Internal IT"

        },
        "Debt Collection": {

            "Id": "bizservice.collection",
            "MarketoMapping": "Internal IT"

        },
        "Management Consulting": {

            "Id": "bizservice.consulting",
            "MarketoMapping": "Internal IT"

        },
        "Information & Document Management": {

            "Id": "bizservice.datamgmt",
            "MarketoMapping": "Office Technology"

        },
        "Multimedia & Graphic Design": {

            "Id": "bizservice.design",
            "MarketoMapping": "Internal IT"

        },
        "Food Service": {

            "Id": "bizservice.foodserv",
            "MarketoMapping": "Internal IT"

        },
        "HR & Staffing": {

            "Id": "bizservice.hr",
            "MarketoMapping": "Internal IT"

        },
        "Facilities Management & Commercial Cleaning": {

            "Id": "bizservice.janitor",
            "MarketoMapping": "Internal IT"

        },
        "Translation & Linguistic Services": {

            "Id": "bizservice.language",
            "MarketoMapping": "Internal IT"

        },
        "Advertising & Marketing": {

            "Id": "bizservice.marketing",
            "MarketoMapping": "Internal IT"

        },
        "Commercial Printing": {

            "Id": "bizservice.printing",
            "MarketoMapping": "Internal IT"

        },
        "Research & Development": {

            "Id": "bizservice.r&d",
            "MarketoMapping": "Internal IT"

        },
        "Security Products & Services": {

            "Id": "bizservice.security",
            "MarketoMapping": "Low Voltage"

        },
        "Custom Software & IT Services": {

            "Id": "bizservice.techconsulting",
            "MarketoMapping": "MSP"

        },
        "Holding Companies & Conglomerates": {

            "Id": "conglomerate",
            "MarketoMapping": "Internal IT"

        },
        "Construction": {

            "Id": "construction",
            "MarketoMapping": "Internal IT"

        },
        "Architecture, Engineering & Design": {

            "Id": "construction.architecture",
            "MarketoMapping": "Internal IT"

        },
        "Civil Engineering Construction": {

            "Id": "construction.civil",
            "MarketoMapping": "Internal IT"

        },
        "Commercial & Residential Construction": {

            "Id": "construction.construction",
            "MarketoMapping": "Internal IT"

        },
        "Consumer Services": {

            "Id": "consumerservices",
            "MarketoMapping": "Internal IT"

        },
        "Automotive Service & Collision Repair": {

            "Id": "consumerservices.auto",
            "MarketoMapping": "Internal IT"

        },
        "Car & Truck Rental": {

            "Id": "consumerservices.carrental",
            "MarketoMapping": "Internal IT"

        },
        "Childcare": {

            "Id": "consumerservices.childcare",
            "MarketoMapping": "Internal IT"

        },
        "Funeral Homes & Funeral Related Services": {

            "Id": "consumerservices.funeralhome",
            "MarketoMapping": "Internal IT"

        },
        "Barber Shops & Beauty Salons": {

            "Id": "consumerservices.hairsalon",
            "MarketoMapping": "Internal IT"

        },
        "Landscape Services": {

            "Id": "consumerservices.landscaping",
            "MarketoMapping": "Internal IT"

        },
        "Cleaning Services": {

            "Id": "consumerservices.laundry",
            "MarketoMapping": "Internal IT"

        },
        "Photography Studio": {

            "Id": "consumerservices.photo",
            "MarketoMapping": "Internal IT"

        },
        "Repair Services": {

            "Id": "consumerservices.repair",
            "MarketoMapping": "Internal IT"

        },
        "Weight & Health Management": {

            "Id": "consumerservices.weight",
            "MarketoMapping": "Internal IT"

        },
        "Education": {

            "Id": "education",
            "MarketoMapping": "Internal IT"

        },
        "K-12 Schools": {

            "Id": "education.k12",
            "MarketoMapping": "Internal IT"

        },
        "Training": {

            "Id": "education.training",
            "MarketoMapping": "Internal IT"

        },
        "Colleges & Universities": {

            "Id": "education.university",
            "MarketoMapping": "Internal IT"

        },
        "Energy, Utilities & Waste": {

            "Id": "energy",
            "MarketoMapping": "Internal IT"

        },
        "Electricity, Oil & Gas": {

            "Id": "energy.energy",
            "MarketoMapping": "Internal IT"

        },
        "Waste Treatment, Environmental Services & Recycling": {

            "Id": "energy.environment",
            "MarketoMapping": "Internal IT"

        },
        "Oil & Gas Exploration & Services": {

            "Id": "energy.services",
            "MarketoMapping": "Internal IT"

        },
        "Water Treatment": {

            "Id": "energy.water",
            "MarketoMapping": "Internal IT"

        },
        "Finance": {

            "Id": "finance",
            "MarketoMapping": "Internal IT"

        },
        "Banking": {

            "Id": "finance.banking",
            "MarketoMapping": "Internal IT"

        },
        "Lending & Brokerage": {

            "Id": "finance.brokerage",
            "MarketoMapping": "Internal IT"

        },
        "Credit Cards & Transaction Processing": {

            "Id": "finance.creditcards",
            "MarketoMapping": "Internal IT"

        },
        "Investment Banking": {

            "Id": "finance.investment",
            "MarketoMapping": "Internal IT"

        },
        "Venture Capital & Private Equity": {

            "Id": "finance.venturecapital",
            "MarketoMapping": "Internal IT"

        },
        "Government": {

            "Id": "government",
            "MarketoMapping": "Internal IT"

        },
        "Federal": {

            "Id": "government.federal",
            "MarketoMapping": "Internal IT"

        },
        "Local": {

            "Id": "government.local",
            "MarketoMapping": "Internal IT"

        },
        "State": {

            "Id": "government.state",
            "MarketoMapping": "Internal IT"

        },
        "Tribal Nations": {

            "Id": "government.tribalnations",
            "MarketoMapping": "Internal IT"

        },
        "Healthcare Services": {

            "Id": "healthservices",
            "MarketoMapping": "Internal IT"

        },
        "Ambulance Services": {

            "Id": "healthservices.ambulance",
            "MarketoMapping": "Internal IT"

        },
        "Blood & Organ Banks": {

            "Id": "healthservices.banks",
            "MarketoMapping": "Internal IT"

        },
        "Medical Laboratories & Imaging Centers": {

            "Id": "healthservices.diagnostics",
            "MarketoMapping": "Internal IT"

        },
        "Elderly Care Services": {

            "Id": "healthservices.elderly",
            "MarketoMapping": "Internal IT"

        },
        "Mental Health & Rehabilitation Facilities": {

            "Id": "healthservices.rehab",
            "MarketoMapping": "Internal IT"

        },
        "Veterinary Services": {

            "Id": "healthservices.veterinary",
            "MarketoMapping": "Internal IT"

        },
        "Hospitality": {

            "Id": "hospitality",
            "MarketoMapping": "Internal IT"

        },
        "Amusement Parks, Arcades & Attractions": {

            "Id": "hospitality.amusement",
            "MarketoMapping": "Internal IT"

        },
        "Movie Theaters": {

            "Id": "hospitality.cinema",
            "MarketoMapping": "Internal IT"

        },
        "Cultural & Informational Centers": {

            "Id": "hospitality.cultural",
            "MarketoMapping": "Internal IT"

        },
        "Fitness & Dance Facilities": {

            "Id": "hospitality.fitness",
            "MarketoMapping": "Internal IT"

        },
        "Gambling & Gaming": {

            "Id": "hospitality.gaming",
            "MarketoMapping": "Internal IT"

        },
        "Libraries": {

            "Id": "hospitality.libraries",
            "MarketoMapping": "Internal IT"

        },
        "Lodging & Resorts": {

            "Id": "hospitality.lodging",
            "MarketoMapping": "Internal IT"

        },
        "Museums & Art Galleries": {

            "Id": "hospitality.museum",
            "MarketoMapping": "Internal IT"

        },
        "Zoos & National Parks": {

            "Id": "hospitality.park",
            "MarketoMapping": "Internal IT"

        },
        "Performing Arts Theaters": {

            "Id": "hospitality.performingarts",
            "MarketoMapping": "Internal IT"

        },
        "Restaurants": {

            "Id": "hospitality.restaurant",
            "MarketoMapping": "Internal IT"

        },
        "Sports Teams & Leagues": {

            "Id": "hospitality.sports",
            "MarketoMapping": "Internal IT"

        },
        "Travel Agencies & Services": {

            "Id": "hospitality.travel",
            "MarketoMapping": "Internal IT"

        },
        "Hospitals & Physicians Clinics": {

            "Id": "hospitals",
            "MarketoMapping": "Internal IT"

        },
        "Physicians Clinics": {

            "Id": "hospitals.clinics",
            "MarketoMapping": "Internal IT"

        },
        "Dental Offices": {

            "Id": "hospitals.dentist",
            "MarketoMapping": "Internal IT"

        },
        "Medical & Surgical Hospitals": {

            "Id": "hospitals.hospital",
            "MarketoMapping": "Internal IT"

        },
        "Medical Specialists": {

            "Id": "hospitals.specialist",
            "MarketoMapping": "Internal IT"

        },
        "Insurance": {

            "Id": "insurance",
            "MarketoMapping": "Internal IT"

        },
        "Law Firms & Legal Services": {

            "Id": "legal",
            "MarketoMapping": "Internal IT"

        },
        "Media & Internet": {

            "Id": "media",
            "MarketoMapping": "Internal IT"

        },
        "Broadcasting": {

            "Id": "media.broadcasting",
            "MarketoMapping": "Internal IT"

        },
        "Data Collection & Internet Portals": {

            "Id": "media.data",
            "MarketoMapping": "Internal IT"

        },
        "Music Production & Services": {

            "Id": "media.music",
            "MarketoMapping": "Internal IT"

        },
        "Newspapers & News Services": {

            "Id": "media.news",
            "MarketoMapping": "Internal IT"

        },
        "Publishing": {

            "Id": "media.publishing",
            "MarketoMapping": "Internal IT"

        },
        "Social Networks": {

            "Id": "media.social",
            "MarketoMapping": "Internal IT"

        },
        "Ticket Sales": {

            "Id": "media.ticketsales",
            "MarketoMapping": "Internal IT"

        },
        "Manufacturing": {

            "Id": "mfg",
            "MarketoMapping": "Internal IT"

        },
        "Aerospace & Defense": {

            "Id": "mfg.aerospace",
            "MarketoMapping": "Internal IT"

        },
        "Appliances": {

            "Id": "mfg.appliances",
            "MarketoMapping": "Internal IT"

        },
        "Boats & Submarines": {

            "Id": "mfg.boat",
            "MarketoMapping": "Internal IT"

        },
        "Building Materials": {

            "Id": "mfg.building",
            "MarketoMapping": "Internal IT"

        },
        "Motor Vehicles": {

            "Id": "mfg.car",
            "MarketoMapping": "Internal IT"

        },
        "Automotive Parts": {

            "Id": "mfg.carparts",
            "MarketoMapping": "Internal IT"

        },
        "Chemicals & Related Products": {

            "Id": "mfg.chemicals",
            "MarketoMapping": "Internal IT"

        },
        "Cleaning Products": {

            "Id": "mfg.cleaning",
            "MarketoMapping": "Internal IT"

        },
        "Textiles & Apparel": {

            "Id": "mfg.clothes",
            "MarketoMapping": "Internal IT"

        },
        "Computer Equipment & Peripherals": {

            "Id": "mfg.computers",
            "MarketoMapping": "VAR"

        },
        "Cosmetics, Beauty Supply & Personal Care Products": {

            "Id": "mfg.cosmetics",
            "MarketoMapping": "Internal IT"

        },
        "Electronics": {

            "Id": "mfg.electronics",
            "MarketoMapping": "Internal IT"

        },
        "Food & Beverage": {

            "Id": "mfg.food",
            "MarketoMapping": "Internal IT"

        },
        "Furniture": {

            "Id": "mfg.furniture",
            "MarketoMapping": "Internal IT"
            ,

            "Id": "retail.furniture",
            "MarketoMapping": "Internal IT"

        },
        "Glass & Clay": {

            "Id": "mfg.glass",
            "MarketoMapping": "Internal IT"

        },
        "Health & Nutrition Products": {

            "Id": "mfg.health",
            "MarketoMapping": "Internal IT"

        },
        "Household Goods": {

            "Id": "mfg.household",
            "MarketoMapping": "Internal IT"

        },
        "Industrial Machinery & Equipment": {

            "Id": "mfg.industrialmachinery",
            "MarketoMapping": "Internal IT"

        },
        "Medical Devices & Equipment": {

            "Id": "mfg.medical",
            "MarketoMapping": "Internal IT"

        },
        "Pulp & Paper": {

            "Id": "mfg.paper",
            "MarketoMapping": "Internal IT"

        },
        "Pet Products": {

            "Id": "mfg.petproducts",
            "MarketoMapping": "Internal IT"
            ,

            "Id": "retail.pet",
            "MarketoMapping": "Internal IT"

        },
        "Pharmaceuticals": {

            "Id": "mfg.pharmaceuticals",
            "MarketoMapping": "Internal IT"

        },
        "Photographic & Optical Equipment": {

            "Id": "mfg.photo",
            "MarketoMapping": "Internal IT"

        },
        "Plastic, Packaging & Containers": {

            "Id": "mfg.plastic",
            "MarketoMapping": "Internal IT"

        },
        "Tires & Rubber": {

            "Id": "mfg.rubber",
            "MarketoMapping": "Internal IT"

        },
        "Sporting Goods": {

            "Id": "mfg.sport",
            "MarketoMapping": "Internal IT"

        },
        "Telecommunication Equipment": {

            "Id": "mfg.telecom",
            "MarketoMapping": "VAR"

        },
        "Test & Measurement Equipment": {

            "Id": "mfg.testequipment",
            "MarketoMapping": "Internal IT"

        },
        "Hand, Power & Lawn-care Tools": {

            "Id": "mfg.tools",
            "MarketoMapping": "Internal IT"

        },
        "Toys & Games": {

            "Id": "mfg.toys",
            "MarketoMapping": "Internal IT"
            ,

            "Id": "retail.toys",
            "MarketoMapping": "Internal IT"

        },
        "Watches & Jewelry": {

            "Id": "mfg.watch",
            "MarketoMapping": "Internal IT"

        },
        "Wire & Cable": {

            "Id": "mfg.wire",
            "MarketoMapping": "Internal IT"

        },
        "Minerals & Mining": {

            "Id": "mm",
            "MarketoMapping": "Internal IT"

        },
        "Organizations": {

            "Id": "orgs",
            "MarketoMapping": "Internal IT"

        },
        "Non-Profit & Charitable Organizations": {

            "Id": "orgs.charitable",
            "MarketoMapping": "Internal IT"

        },
        "Membership Organizations": {

            "Id": "orgs.membership",
            "MarketoMapping": "Internal IT"

        },
        "Religious Organizations": {

            "Id": "orgs.religious",
            "MarketoMapping": "Internal IT"

        },
        "Real Estate": {

            "Id": "realestate",
            "MarketoMapping": "Internal IT"

        },
        "Retail": {

            "Id": "retail",
            "MarketoMapping": "Internal IT"

        },
        "Auctions": {

            "Id": "retail.auction",
            "MarketoMapping": "Internal IT"

        },
        "Automobile Dealers": {

            "Id": "retail.auto",
            "MarketoMapping": "Internal IT"

        },
        "Automobile Parts Stores": {

            "Id": "retail.autoparts",
            "MarketoMapping": "Internal IT"

        },
        "Record, Video & Book Stores": {

            "Id": "retail.book",
            "MarketoMapping": "Internal IT"

        },
        "Apparel & Accessories Retail": {

            "Id": "retail.clothes",
            "MarketoMapping": "Internal IT"

        },
        "Consumer Electronics & Computers Retail": {

            "Id": "retail.computer",
            "MarketoMapping": "VAR"

        },
        "Convenience Stores, Gas Stations & Liquor Stores": {

            "Id": "retail.conveniencestore",
            "MarketoMapping": "Internal IT"

        },
        "Department Stores, Shopping Centers & Superstores": {

            "Id": "retail.departmentstore",
            "MarketoMapping": "Internal IT"

        },
        "Flowers, Gifts & Specialty Stores": {

            "Id": "retail.gifts",
            "MarketoMapping": "Internal IT"

        },
        "Grocery Retail": {

            "Id": "retail.grocery",
            "MarketoMapping": "Internal IT"

        },
        "Home Improvement & Hardware Retail": {

            "Id": "retail.hardware",
            "MarketoMapping": "Internal IT"

        },
        "Vitamins, Supplements & Health Stores": {

            "Id": "retail.health",
            "MarketoMapping": "Internal IT"

        },
        "Jewelry & Watch Retail": {

            "Id": "retail.jewelry",
            "MarketoMapping": "Internal IT"

        },
        "Office Products Retail & Distribution": {

            "Id": "retail.office",
            "MarketoMapping": "Internal IT"

        },
        "Drug Stores & Pharmacies": {

            "Id": "retail.pharmacy",
            "MarketoMapping": "Internal IT"

        },
        "Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)": {

            "Id": "retail.rental",
            "MarketoMapping": "Internal IT"

        },
        "Sporting & Recreational Equipment Retail": {

            "Id": "retail.sports",
            "MarketoMapping": "Internal IT"

        },
        "Software": {

            "Id": "software",
            "MarketoMapping": "VAR"

        },
        "Mobile App Development": {

            "Id": "software.app",
            "MarketoMapping": "VAR"

        },
        "Business Intelligence (BI) Software": {

            "Id": "software.bi",
            "MarketoMapping": "VAR"

        },
        "Content & Collaboration Software": {

            "Id": "software.contentcol",
            "MarketoMapping": "VAR"

        },
        "Customer Relationship Management (CRM) Software": {

            "Id": "software.crm",
            "MarketoMapping": "VAR"

        },
        "Database & File Management Software": {

            "Id": "software.db",
            "MarketoMapping": "VAR"

        },
        "Engineering Software": {

            "Id": "software.eng",
            "MarketoMapping": "VAR"

        },
        "Enterprise Resource Planning (ERP) Software": {

            "Id": "software.erp",
            "MarketoMapping": "VAR"

        },
        "Financial Software": {

            "Id": "software.finance",
            "MarketoMapping": "VAR"

        },
        "Healthcare Software": {

            "Id": "software.health",
            "MarketoMapping": "VAR"

        },
        "Human Resources Software": {

            "Id": "software.hr",
            "MarketoMapping": "VAR"

        },
        "Legal Software": {

            "Id": "software.legal",
            "MarketoMapping": "VAR"

        },
        "Networking Software": {

            "Id": "software.network",
            "MarketoMapping": "VAR"

        },
        "Supply Chain Management (SCM) Software": {

            "Id": "software.scm",
            "MarketoMapping": "VAR"

        },
        "Security Software": {

            "Id": "software.security",
            "MarketoMapping": "Managed Security"

        },
        "Storage & System Management Software": {

            "Id": "software.storage",
            "MarketoMapping": "VAR"

        },
        "Multimedia, Games & Graphics Software": {

            "Id": "software.videogames",
            "MarketoMapping": "VAR"

        },
        "Telecommunications": {

            "Id": "telecom",
            "MarketoMapping": "Telecom/Collaboration"

        },
        "Cable & Satellite": {

            "Id": "telecom.cable",
            "MarketoMapping": "Telecom/Collaboration"

        },
        "Internet Service Providers, Website Hosting & Internet-related Services": {

            "Id": "telecom.internet",
            "MarketoMapping": "Telecom/Collaboration"

        },
        "Telephony & Wireless": {

            "Id": "telecom.telephone",
            "MarketoMapping": "Telecom/Collaboration"

        },
        "Transportation": {

            "Id": "transportation",
            "MarketoMapping": "Internal IT"

        },
        "Airlines, Airports & Air Services": {

            "Id": "transportation.airline",
            "MarketoMapping": "Internal IT"

        },
        "Freight & Logistics Services": {

            "Id": "transportation.freight",
            "MarketoMapping": "Internal IT"

        },
        "Marine Shipping & Transportation": {

            "Id": "transportation.marine",
            "MarketoMapping": "Internal IT"

        },
        "Trucking, Moving & Storage": {

            "Id": "transportation.moving",
            "MarketoMapping": "Internal IT"

        },
        "Rail, Bus & Taxi": {

            "Id": "transportation.railandbus",
            "MarketoMapping": "Internal IT"
        }
    }
}
   

    
