﻿
module.exports = {
    'InitForms': require('./init-forms').default,
    'DriftRedirect': require('./drift-redirect'),
    'ZoomInfo': require('./zoom-info'),
    'JQueryValidationExtensions': require('./jquery-validation-extensions'),
    'Effects': require('./effects'),
    'Validation': require('./validation'),
    'FormSuccess': require('./form-success'),
    'FormSubmitOverride': require('./submit-override'),
};