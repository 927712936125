import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
const galleryCarouselInit = () => {
    const galleryCarousels = document.querySelectorAll('.gallery-carousel');
    if (!galleryCarousels)
        return;
    galleryCarousels.forEach((gallery) => {
        const galleryId = gallery.getAttribute('id');
        const dataGalleryId = `[data-galleryId="${galleryId}"]`;
        const navigation = {
            nextEl: `.gallery-carousel__next${dataGalleryId}`,
            prevEl: `.gallery-carousel__prev${dataGalleryId}`,
        };
        var swiper = new Swiper(`.gallery-carousel__thumb-slider${dataGalleryId}`, {
            modules: [Navigation, Pagination],
            spaceBetween: 32,
            slidesPerView: 4,
            loop: true,
            watchSlidesProgress: true,
            navigation: {
                ...navigation,
            },
            breakpoints: {
                1024: {
                    navigation: {
                        enabled: true,
                        ...navigation,
                    },
                },
            },
        });
        var swiper2 = new Swiper(`.gallery-carousel__main-slider${dataGalleryId}`, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            thumbs: {
                swiper: swiper,
            },
            centeredSlides: true,
            loop: true,
            modules: [Navigation, Pagination, Thumbs],
            navigation: {
                ...navigation,
            },
            pagination: {
                enabled: true,
                el: `.swiper-pagination${dataGalleryId}`,
                clickable: true,
            },
            breakpoints: {
                1024: {
                    pagination: {
                        enabled: false,
                        el: `.swiper-pagination${dataGalleryId}`,
                        clickable: true,
                    },
                },
            },
        });
        swiper.updateSize();
    });
};
export default galleryCarouselInit;
