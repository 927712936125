import { Cookie } from './cookie';
import { Utils } from 'Presentation/js/utils';
export class MultisiteCookie {
    static MergeInCookieObject(obj) {
        let v = MultisiteCookie.GetMultisiteCookieObject();
        let r = { ...v, ...obj };
        Cookie.storeObject("cw__shared", r, 1, null, false, true);
    }
    static GetMultisiteCookieObject() {
        return Cookie.loadObject("cw__shared") || {};
        ;
    }
    isConnectWiseDomain(domain) {
        let domains = [];
        $.ajax({
            method: "GET",
            url: "/api/crossdomain/list-all",
            async: false,
            success: function (data) {
                domains = data;
            }
        });
        return domains.findIndex((v) => { return v === domain; }) !== -1;
    }
    share() {
        var ref = window.document.referrer;
        var current = window.document.location.href;
        if (ref && current) {
            var refUrl = new URL(ref);
            var currentUrl = new URL(current);
            if (!refUrl.hostname)
                return;
            if (refUrl.host !== currentUrl.host && this.isConnectWiseDomain(refUrl.hostname)) {
                //boundary between connectwise websites
                this.getSharedCookie(refUrl, currentUrl);
            }
        }
    }
    static Init() {
        var obj = new MultisiteCookie();
        obj.share();
    }
    static OnSharedCookieSet(callback) {
        var w = window;
        if (w.OnSharedCookieEvent === "loaded") {
            let obj = Cookie.loadObject("cw__shared");
            callback(obj);
            return;
        }
        w.OnSharedCookieEvent = w.OnSharedCookieEvent || [];
        w.OnSharedCookieEvent.push(callback);
    }
    getSharedCookie(refUrl, currentUrl) {
        window.addEventListener("message", (event) => {
            if (event.origin !== refUrl.origin) {
                return;
            }
            var w = window;
            let ctx = {
                preveous: Cookie.loadObject("cw__shared") || {},
                response: {},
                new: {}
            };
            if (event.data) {
                let content = atob(event.data);
                ctx.new = JSON.parse(content);
            }
            ctx.response = Object.assign(ctx.preveous, Utils.ObjectProcess.removeNullEmptyProperties({ ...ctx.new }));
            if (w.OnSharedCookieEvent && w.OnSharedCookieEvent.length) {
                for (var callback of w.OnSharedCookieEvent) {
                    callback(ctx);
                }
            }
            Cookie.storeObject("cw__shared", ctx.response, 1, null, false, true);
            w.OnSharedCookieEvent = "loaded";
        });
        document.body.innerHTML += `<iframe style="display:none" src="${refUrl.origin}/api/crossdomain"></iframe>`;
    }
}
