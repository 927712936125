export function tagsWithExpanderInit() {
    const tagButton = document.querySelectorAll('.js-tag-expander-button');
    tagButton.forEach((el) => {
        const additionalTags = el.nextElementSibling;
        if (additionalTags === null)
            return;
        function showTags(e) {
            e.preventDefault();
            additionalTags.classList.add('tags-with-expander__additional-tags--visible');
        }
        function hideTags(e) {
            e.preventDefault();
            additionalTags.classList.remove('tags-with-expander__additional-tags--visible');
        }
        ['mouseenter', 'touchstart'].forEach((evt) => {
            el.addEventListener(evt, showTags);
        });
        ['mouseleave', 'touchend'].forEach((evt) => {
            el.addEventListener(evt, hideTags);
        });
    });
}
