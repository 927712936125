import initCertifiedToggle from './certifiedToggle';
import initFilters from './filters';
import getSearchResultsElements from './getElements';
import initPagination from './pagination';
import initSearchField from './searchField';
import getSearchSettings from './searchSettings';
import initSort from './sort';
export function initSearchResults() {
    const elements = getSearchResultsElements();
    if (elements === null) {
        return;
    }
    const searchSettings = getSearchSettings(elements);
    console.log('initSearchResults', { elements, searchSettings });
    initSearchField(elements, searchSettings);
    initFilters(elements, searchSettings);
    initCertifiedToggle(elements, searchSettings);
    initSort(elements, searchSettings);
    initPagination(elements, searchSettings);
}
