﻿module.exports = function () {
    //Add a Jquery Validation custom method to be used on marketo forms that also use
    //JQuery validation
    jQuery.validator.addMethod("async", function (value, element, param,method) {
		if (this.optional(element)) {
			return "dependency-mismatch";
		}

		method = typeof method === "string" && method || "async";

		var previous = this.previousValue(element, method),
			validator, data, optionDataString;

		if (!this.settings.messages[element.name])
		{
			this.settings.messages[element.name] = {};
		}

		previous.originalMessage = previous.originalMessage || this.settings.messages[element.name][method];
		this.settings.messages[element.name][method] = previous.message;

		param = typeof param === "string" && { url: param } || param;
		optionDataString = $.param($.extend({ data: value }, param.data));
		if (previous.old === optionDataString) {
			return previous.valid;
		}

		previous.old = optionDataString;
		validator = this;
		this.startRequest(element);
		data = {};
		data[element.name] = value;


		if (!param.method || typeof  param.method !== "function") {
			throw "Jquery Validation: asyncValidation was not provided with a method"
			return false;
		}

		param.method(value,element).then((errorMessage) => {
			var valid = !errorMessage,
				errors, message, submitted;

			validator.settings.messages[element.name][method] = previous.originalMessage;
			if (valid) {
				submitted = validator.formSubmitted;
				validator.resetInternals();
				validator.toHide = validator.errorsFor(element);
				validator.formSubmitted = submitted;
				validator.successList.push(element);
				validator.invalid[element.name] = false;
				validator.showErrors();
			} else {
				errors = {};
				message = errorMessage || validator.defaultMessage(element, { method: method, parameters: value });
				errors[element.name] = previous.message = message;
				validator.invalid[element.name] = true;
				validator.showErrors(errors);
			}
			previous.valid = valid;
			validator.stopRequest(element, valid);
		}, (error) => {
			errors = {};
			message = "Could not validate";
			errors[element.name] = previous.message = message;
			validator.stopRequest(element,false);
			validator.invalid[element.name] = true;
			validator.showErrors(errors);
		});
		return "pending";
	}, "Invalid Field")


	jQuery.validator.addMethod("emailDomainRestriction", function (value, element, param, method) {
		let $form = $(element).parents('form');
		const excludedDomains = $form.data('restricted-domains');
		method= method || "emailDomainRestriction";
		if ($form.data('restricted-domain-action') !== 'error')
			return true;

		value = value.trim().toLowerCase();
		var passedValidation = true;

		if (excludedDomains) {
			excludedDomains.split(',').forEach(function (item) {
				if (item && item.length) {
					item = item.trim().toLowerCase();
					if (item.charAt(0) !== '@') {
						item = '@' + item;
					}
					if (value.endsWith(item)) {
						passedValidation = false;
					}
				}
			});
		}

		if (!passedValidation) {
			let message = $form.data("restricted-domain-message");
			errors = {};
			this.settings.messages[element.name] = { "emailDomainRestriction": message };
			errors[element.name] = message;
			this.invalid[element.name] = true;
			this.showErrors(errors);
			return false;
		} 
		return true;
	},"Business Email Not Allowed")


	return {};


	
}();
