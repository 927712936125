import { modalInit } from '../modal';
import favoriteIconInit from '../favoriteIcon';
import { TrustPilotHotReload } from '../trustpilot';
import { isCertifiedToggleOn } from './certifiedToggle';
import initPagination from './pagination';
import { insertQueryParamToCurrentUrl } from './utils';
const doSearchContent = (page, elements, searchSettings) => {
    const { searchApi, scopeId, resultBlockId } = searchSettings;
    const { searchInput, progressing, sortSelect, searchResultsContainer, searchResultsCount, noResultContent } = elements;
    progressing.style.display = 'inline-block';
    setTimeout(function () {
        if (!searchSettings)
            return;
        var xhttp = new XMLHttpRequest();
        var facetFilters = [];
        var keyFilters = [];
        document.querySelectorAll('.js-selected-filter-remove').forEach((element) => {
            if (element.classList.contains('js-selected-key-remove')) {
                keyFilters.push(element.getAttribute('data-facet-value-key'));
            }
            else {
                facetFilters.push(element.getAttribute('data-facet-value-key'));
            }
        });
        const searchApi = searchSettings.searchApi;
        const resultBlockId = searchSettings.resultBlockId;
        const scopeId = searchSettings.scopeId;
        const pageSize = searchSettings.pageSize;
        const barSize = searchSettings.barSize;
        const currentPageId = searchSettings.currentPageId;
        const keyword = searchInput ? searchInput.value : '';
        const filters = facetFilters.join(',');
        const keys = keyFilters.join(',');
        const certifiedToggle = isCertifiedToggleOn(elements) ? 'true' : 'false';
        var apiRequestUrl = `${searchApi}?resultBlockId=${resultBlockId}&scopeId=${scopeId}&currentPageId=${currentPageId}&q=${keyword}&keys=${keys}&filters=${filters}&certifiedToggle=${certifiedToggle}&page=${page}&size=${pageSize}&bar=${barSize}`;
        if (sortSelect) {
            apiRequestUrl = `${searchApi}?resultBlockId=${resultBlockId}&scopeId=${scopeId}&currentPageId=${currentPageId}&q=${keyword}&keys=${keys}&filters=${filters}&certifiedToggle=${certifiedToggle}&sort=${sortSelect.value}&page=${page}&size=${pageSize}&bar=${barSize}`;
        }
        xhttp.open('GET', apiRequestUrl, false);
        xhttp.send();
        if (xhttp.status == 200) {
            const searchResultItems = searchResultsContainer.querySelector('.search-results-items');
            if (searchResultItems)
                searchResultItems.innerHTML = xhttp.responseText;
            initPagination(elements, searchSettings);
            modalInit();
            favoriteIconInit();
            TrustPilotHotReload.Run();
            const inputHiddenTotalCount = searchResultsContainer.querySelector('.js-input-hidden-total-count');
            if (inputHiddenTotalCount) {
                if (searchResultsCount) {
                    searchResultsCount.innerHTML = inputHiddenTotalCount.value;
                }
                if (noResultContent) {
                    const totalCount = parseInt(inputHiddenTotalCount.value);
                    if (totalCount > 0) {
                        noResultContent.style.display = 'none';
                        searchResultsContainer.style.display = '';
                    }
                    else {
                        noResultContent.style.display = '';
                        searchResultsContainer.style.display = 'none';
                    }
                }
            }
        }
        else {
            if (searchResultsCount) {
                searchResultsCount.innerHTML = '0';
            }
            if (noResultContent) {
                searchResultsContainer.innerHTML = '';
                noResultContent.style.display = '';
                searchResultsContainer.style.display = 'none';
            }
        }
        let newQueryString = window.location.search.substring(1);
        newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'q', keyword);
        newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'keys', keys);
        newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'filters', filters);
        newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'scopeId', scopeId);
        if (isCertifiedToggleOn) {
            newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'certifiedToggle', certifiedToggle);
        }
        newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'page', page);
        if (sortSelect) {
            newQueryString = insertQueryParamToCurrentUrl(newQueryString, 'sort', sortSelect.value);
        }
        window.history.pushState(null, null, window.location.pathname + `?${newQueryString}`);
        progressing.style.display = 'none';
    }, 100);
};
export default doSearchContent;
