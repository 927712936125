const getSearchResultsElements = () => {
    const searchResultsBlock = document.querySelector('.js-search-results-block');
    const searchResultsContainer = document.querySelector('.js-search-results');
    if (!searchResultsBlock || !searchResultsContainer) {
        return null;
    }
    const searchInput = document.querySelector('#search-input');
    const searchButton = document.querySelector('.js-search-button');
    const searchInputClear = document.querySelector('.js-search-input-clear');
    const searchResultsCount = document.querySelector('.js-search-results-count');
    const filterCheckboxes = document.querySelectorAll('.js-checkbox-filter');
    const filterReset = document.querySelector('.js-reset-filters');
    const selectedFilters = document.querySelector('.js-selected-filters');
    const noResultContent = document.querySelector('.js-no-content-result');
    const progressing = document.querySelector('.js-progressing');
    const filtersContainer = document.querySelector('.js-search-results-facets');
    const hideFilters = document.querySelector('.js-hide-filters');
    const showFilters = document.querySelector('.js-show-filters');
    const sortSelect = document.querySelector('#sort-select');
    const certifiedToggle = document.querySelector('.js-certified-toggle');
    return {
        searchResultsBlock,
        searchResultsContainer,
        searchInput,
        searchButton,
        searchInputClear,
        searchResultsCount,
        filterCheckboxes,
        filterReset,
        selectedFilters,
        noResultContent,
        progressing,
        filtersContainer,
        hideFilters,
        showFilters,
        sortSelect,
        certifiedToggle,
    };
};
export default getSearchResultsElements;
