export function listCtaAccordionInit() {
    // get a list of all the List CTA accordions and loop through them
    const listCtaAccordions = document.querySelectorAll('.list-cta-accordion-heading');
    listCtaAccordions.forEach((listCta) => {
        // add the onclick listener to each...
        listCta.addEventListener('click', (event) => {
            // determine if it's active or not
            listCta.classList.toggle('active');
            const isActive = listCta.classList.contains('active');
            const expandIcon = listCta.querySelector('.fa-solid');
            // if it's active, clicking will hide the matching content
            if (isActive) {
                expandIcon.classList.remove('fa-plus');
                expandIcon.classList.add('fa-minus');
            }
            else {
                expandIcon.classList.remove('fa-minus');
                expandIcon.classList.add('fa-plus');
            }
        });
    });
}
