﻿module.exports = function(url,otIgnore) {
    let v =  globalThis._cwIncludedScripts = globalThis._cwIncludedScripts || [];
    if (v.findIndex(url) === -1) {
        v.push(url);
        let s = document.createElement('script');
        s.src = url;
        s.type = 'text/javascript';
        s.async = true;
        if (otIgnore)
            s.setAttribute("data-ot-ignore", "")
        document.head.appendChild(s);
    }
}