import { debounce } from 'lodash';
import { desktopBreakpointMin } from './header';
// import 'Areas/ConnectWise/Presentation/js/functions/easyResponsiveTabs.modified.js';

const breakPoint = 1024;
var Typeahead = require('typeahead');

export function mainNavigationInit() {
  //hamburger-close button for mobile
  function toggleMobileMenu() {
    const closeButton = document.querySelector('.close-menu');
    const menu = document.querySelector('.resp-tabs-container.hor-1');
    const html = document.querySelector('html');

    if (closeButton != null && menu != null && html != null) {
      closeButton.addEventListener('click', function () {
        closeButton.classList.toggle('active');
        menu.classList.toggle('active');
        if (closeButton.classList.contains('active')) {
          html.classList.add('hide-overflow');
        } else {
          html.classList.remove('hide-overflow');
        }
      });
    }
  }

  //resets all open tabs to close for initial state
  function resetActiveTabs() {
    const activeTabDesktops = document.querySelectorAll('.resp-tab-item.hor-1.resp-tab-active');
    const activeTabMobiles = document.querySelectorAll('.resp-accordion.hor-1.resp-tab-active');
    const activePanes = document.querySelectorAll('.resp-tab-content.hor-1.resp-tab-content-active');
    const activeVerticalTabs = document.querySelectorAll('.main-navigation .resp-accordion.resp-tab-active');
    const activeVerticalPanes = document.querySelectorAll('.main-navigation .resp-tab-content.resp-tab-content-active');
    const screenWidth = document.documentElement.clientWidth || window.innerWidth;

    // Loop through and remove the class from each element
    activeTabDesktops.forEach((element) => {
      element.classList.remove('resp-tab-active');
    });
    activeTabMobiles.forEach((element) => {
      element.classList.remove('resp-tab-active');
    });
    activePanes.forEach((element) => {
      element.classList.remove('resp-tab-content-active');
      element.style.display = '';
    });

    if (screenWidth < breakPoint) {
      activeVerticalTabs.forEach((element) => {
        element.classList.remove('resp-tab-active');
      });
      activeVerticalPanes.forEach((element) => {
        element.classList.remove('resp-tab-content-active');
        element.style.display = '';
      });
    }
  }

  //close tabs if clicked outside of menu
  function handleClickOutside(event) {
    const mainNavigation = document.getElementById('main-navigation');
    const tabcordion = document.querySelector('.main-navigation .resp-tabs-container hor-1');

    if (mainNavigation && !mainNavigation.contains(event.target)) {
      if (tabcordion && !tabcordion.contains(event.target)) {
        // Clicked inside a .tabcordion element, do nothing.
        return;
      }
      return resetActiveTabs();
    }
  }

  // Move utility navigation within the DOM per the design
  function moveUtilityNav() {
    const example = document.querySelector('.resp-tabs-container.hor-1');
    const utilityNav = document.querySelector('.utility-navigation');
    const header = document.getElementById('PageHeader');
    const screenWidth = document.documentElement.clientWidth || window.innerWidth;
    
    if(!utilityNav) return;

    if (screenWidth <= desktopBreakpointMin) {
      // Move utility-nav as the last child of example for screens below 1024px
      example.appendChild(utilityNav);
    } else if (header !== null && screenWidth > desktopBreakpointMin) {
      // Move utility-nav back to its original position for screens above 1024px
      header.insertBefore(utilityNav, header.firstChild);
    }
  }

  // Expand search bar
  function searchBarInit() {
    const mainNavigation = document.querySelector('.main-navigation');
    const searchButtons = document.querySelectorAll('.search-bar .search-button');
    const searchInputs = document.querySelectorAll('.search-bar .js-search-input');
    const closeSearchBarButtons = document.querySelectorAll('.close-searchbar-modal, .close-search');

    var executeSearchPageQuery = (searchPageUrl, queryString) => {
      window.location = `${searchPageUrl}?q=${queryString}`;
    };

    var handleSearchButton = (searchButton) => {
      searchButton.addEventListener('click', function () {
        const searchBar = searchButton.closest('.search-bar') ?? searchButton.nextElementSibling;

        if (!mainNavigation.classList.contains('search-bar-expanded')) {
          // if search bar is not open, open it
          mainNavigation?.classList.add('search-bar-expanded');
          searchBar?.classList.add('expanded');
        } else {
          // if search bar is already open, then just execute the query
          const searchPageUrl = searchBar && searchBar.getAttribute('data-search-page');
          const searchInput = searchBar.querySelector('.js-search-input');

          executeSearchPageQuery(searchPageUrl, searchInput.value);
        }
      });
    };

    var closeSearchBar = (closeButton) => {
      closeButton.addEventListener('click', () => {
        const searchBar = closeButton.closest('.search-bar') ?? closeButton.nextElementSibling;

        mainNavigation?.classList.remove('search-bar-expanded');
        searchBar?.classList.remove('expanded');
      });
    };

    searchButtons.forEach(handleSearchButton);
    closeSearchBarButtons.forEach(closeSearchBar);

    searchInputs.forEach((searchInput) => {
      const searchBar = searchInput.closest('.search-bar');
      const autoSuggestionApi = searchBar && searchBar.getAttribute('data-auto-suggest-api');
      let getAutoSuggestionTimeout = null;

      if (!autoSuggestionApi) return;

      var typeahead = Typeahead(searchInput, {
        items: 10,
        minLength: 3,
        source: function (query, process) {
          if (getAutoSuggestionTimeout) {
            clearTimeout(getAutoSuggestionTimeout);
          }
          getAutoSuggestionTimeout = setTimeout(() => {
            return $.get(autoSuggestionApi, { prefix: query }, function (data) {
              var result = process(data);
              $(result.menu).width($(result.element).width());
              return result;
            });
          }, 500);
        },
      });

      const searchPageUrl = searchBar && searchBar.getAttribute('data-search-page');

      searchInput.addEventListener('keyup', function (event) {
        if ((event.key && event.key === 'Enter') || event.keyCode === 13) {
          executeSearchPageQuery(searchPageUrl, searchInput.value);
        }
      });
      typeahead.menu.on('click', function (event) {
        executeSearchPageQuery(searchPageUrl, searchInput.value);
      });
    });
  }

  /**
   * Applies a mutation observer to listen when tab states change
   */
  function handleTabTreeClassChange() {
    const tabsContainer = document.querySelector('.resp-tabs-container.hor-1');

    const tabsObserver = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        // if a tab is open/closed - toggle tab container class
        if (mutation.target?.matches('.resp-accordion.hor-1.resp-tab-active')) {
          tabsContainer.classList.add('has-active-tab-child');
        } else if (mutation.target?.matches('.resp-accordion.hor-1')) {
          tabsContainer.classList.remove('has-active-tab-child');
        }
      }
    });

      if (tabsContainer) {
          tabsObserver.observe(tabsContainer, {
              attributes: true,
              childList: true,
              subtree: true,
              attributeFilter: ['class'],
          });
      }    
  }

  // initializes horizontal and vertical tabs (easyResponsiveTabs JS)
  window.addEventListener('load', function () {
    //Horizontal Tab
    $('#main-navigation').easyResponsiveTabs({
      type: 'default', //Types: default, vertical, accordion
      width: 'auto', //auto or any width like 600px
      fit: true, // 100% fit in a container
      tabidentify: 'hor-1', // The tab groups identifier
    });

    // Child Tab
    $('#tabcordion-vertical-1').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      tabidentify: 'ver-1', // The tab groups identifier
    });

    // Child Tab
    $('#tabcordion-vertical-2').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      tabidentify: 'ver-2', // The tab groups identifier
    });

    // Child Tab
    $('#tabcordion-vertical-3').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      tabidentify: 'ver-3', // The tab groups identifier
    });

    $('#tabcordion-vertical-1').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      tabidentify: 'ver-1', // The tab groups identifier
    });

    $('.tabcordion-container').each(function () {
      $(this).easyResponsiveTabs({
        type: $(this).attr('data-tab-type'),
        width: 'auto',
        fit: true,
        tabidentify: $(this).attr('data-tab-id'), // The tab groups identifier
      });
    });

    //call functions on page load
    toggleMobileMenu();
    resetActiveTabs();

    searchBarInit();
    handleTabTreeClassChange();

    document.addEventListener('click', handleClickOutside, false);
  });
}
