export class ObjectProcess {
    removeNullEmptyProperties(obj) {
        for (var key in obj) {
            if (obj[key] === null || obj[key] === '')
                obj[key] = undefined;
        }
        return obj;
    }
}
const URI = require('urijs');
export class Utils {
    static ObjectProcess = new ObjectProcess();
    static GetQueryParams() {
        //get parameters from query string
        var uri = new URI(window.location.href);
        var parameters = URI.parseQuery(uri._parts.query);
        return parameters ? parameters : {};
    }
    static serializeToFormData(data) {
        return Object.keys(data)
            .map(key => `${key}=${encodeURIComponent(data[key])}`)
            .join('&');
    }
}
