var zoomInfo = require('./zoom-info');
var driftMarketo = require('./drift');
import { Captcha } from 'Presentation/js/marketo/captcha';
import { GeoFields } from './_geo-fields';
import { FormEffects } from './form-effects';
import $ from 'jquery';
import * as jqueryValidation from './_validation';
import { ReferrerUrlDetection } from './_referrer-url-detection';
import { SMSAuth } from './_sms-auth';
import { UTMHandler } from 'Presentation/js/connectwise/utils/utm-handler';
export default function (config) {
    MktoForms2 = MktoForms2 || {};
    MktoForms2.setOptions({
        formXDPath: '/rs/' + config.munchkinId + '/images/marketo-xdframe-relative.html',
    });
    //Detect the external domain from which the user accessed connectwise.com
    ReferrerUrlDetection.storeExternalDomainRef();
    MktoForms2.whenRendered(function (form) {
        var $form = $(form.getFormElem());
        addHiddenFieldsIfKnownVisitorForm(form);
        $form.find('style').remove();
        var $elemsToRemove = $form.find('.mktoClear,.mktoGutter,.mktoOffset,.mktoInstruction');
        $elemsToRemove.remove();
        $form.find('.mktoFieldWrap').each(function () {
            var $fieldWrap = $(this);
            var $label = $fieldWrap.find('.mktoLabel');
            var $asterix = $label.find('.mktoAsterix');
            if ($asterix.length) {
                $asterix.remove();
            }
            var parsed = parseLabel($label.text());
            var $field = $fieldWrap.find('.mktoField');
            if ($field.length && $label.length) {
                if ($field.prop('type') === 'checkbox') {
                    $field.addClass('apple-switch');
                    $label.remove();
                    $field.after(`<label for="${$field[0].name}" class="mktoHtmlText checkbox-label">${parsed.label}</label>`);
                }
                else if ($field.prop('tagName') === 'INPUT' || $field.prop('tagName') === 'TEXTAREA') {
                    $field.attr('placeholder', parsed.label);
                    $label.remove();
                }
                else if ($field.prop('tagName') === 'SELECT') {
                    var $firstOption = $field.find('option:eq(0)');
                    if (parsed.label)
                        $firstOption.text(parsed.label);
                    $label.remove();
                }
            }
            //if (parsed.upText) {
            //  $fieldWrap.prepend(`<label for="${($field[0] as HTMLInputElement).name}" class="uptext-label">${parsed.label}</div>`);
            //  }
            if ($field[0] instanceof HTMLInputElement) {
                $fieldWrap.prepend(`<label for="${$field[0].name}" class="uptext-label">${parsed.label}</label>`);
            }
        });
        //fiels who have4 password in their names  must be set as type password
        $form.find('input').each(function () {
            var $input = $(this);
            var name = $input.attr('name');
            if (name && name.toLowerCase().indexOf('password') !== -1) {
                $input.attr('type', 'password');
            }
        });
        $form.find('input[type=hidden]').each(function () {
            var $input = $(this);
            $input.parents('.mktoFormRow').appendTo($form).addClass('mktoFormRowHidden');
        });
        $form.find('.mktoLogicalField').each(function () {
            var $field = $(this);
            $field.parents('.mktoFormRow').addClass('mktoFormRowLogical');
        });
        const $submitButtonTextReplacement = $form.parents('[data-submit-button-text]').first();
        const buttonText = $submitButtonTextReplacement.length
            ? $submitButtonTextReplacement.data('submitButtonText')
            : $form.data('submitButtonText');
        if (buttonText || buttonText == '') {
            $form.find('[type=submit]').each(function () {
                if ($(this).is('input')) {
                    $(this).val(buttonText);
                }
                else if ($(this).is('button')) {
                    $(this).text(buttonText);
                }
            });
        }
        let initValues = window.mktoInitValues || {};
        const poi = $form.data('poi');
        if (poi) {
            initValues['mKTOProductInterest'] = poi;
        }
        const emailPreFill = $form.data('emailPrefill');
        if (emailPreFill) {
            initValues['Email'] = emailPreFill;
            let $email = $form.find("#Email");
            $email.val(emailPreFill);
            $email.prop('readonly', true);
        }
        ;
        //url
        let url = new URL(window.location.href);
        url.hash = '';
        url.search = '';
        initValues["formSubmissionURLPathName"] = url.href;
        const pageSource = $form.data('pageSource');
        if (pageSource) {
            initValues['pageSource'] = pageSource;
        }
        const campaignCode = $form.data('campaignCode');
        if (campaignCode) {
            initValues['campaignCodeMostRecent'] = campaignCode;
        }
        const gclid = $form.data('gclid');
        if (gclid) {
            initValues['GCLID__c'] = gclid;
        }
        var gaClientId = localStorage.getItem('gaclientId');
        if (gaClientId)
            initValues['ga_cid__c'] = localStorage.getItem('gaclientId');
        //referring url
        if (ConnectWise.Utils.ExternalDomain.getExternalDomain()) {
            initValues['referringURL'] = ConnectWise.Utils.ExternalDomain.getExternalDomain();
        }
        if (window.geoIPCountry === 'US' && window.geoIPState !== 'CA') {
            const $optInCheckbox = $form.find('#emailOptIn');
            if ($optInCheckbox.length) {
                $optInCheckbox.prop('checked', true);
            }
        }
        form.vals(initValues);
        //Add special location fields if Geo IP failes
        GeoFields.Init(form);
        //Add jquery validation
        jqueryValidation.Init(form);
        //Captcha Initialization
        let captcha = new Captcha(form);
        captcha.Init();
        //ZoomInfo Initialization
        zoomInfo.Init(form);
        hideFields($form);
        //Init event if supplied
        const initFunctionName = $form.data('onRender');
        if (initFunctionName) {
            var f = ConnectWise.Utils.GetMethod(initFunctionName);
            f(form);
        }
    });
    var onlyUnique = function (value, index, self) {
        return self.indexOf(value) === index;
    };
    driftMarketo.Init();
    var formIds = $('form[data-form-id]')
        .toArray()
        .map(function (el) {
        return $(el).data('formId');
    })
        .filter(onlyUnique);
    //We need to wait for the form load function to finish before loading the next form
    (function loadRecursive(formElemIds) {
        let formId = formElemIds.shift();
        if (!formId) {
            driftMarketo.Init();
            return;
        }
        console.log('load form: ' + formId);
        //add the correct id to the form
        $('form[data-form-id="' + formId + '"]').each((i, el) => {
            $(el).attr('id', 'mktoForm_' + formId);
        });
        MktoForms2.loadForm(config.podId, config.munchkinId, formId, function (form) {
            let $form = $(form.getFormElem());
            loadRecursive(formElemIds);
        });
    })(formIds);
    MktoForms2.whenReady(_initForm);
}
let _initForm = function (form) {
    if (form) {
        let $form = $(form.getFormElem());
        let effect = new FormEffects($form);
        const formSubmitFunctionName = $form.data('onSubmit');
        if (formSubmitFunctionName) {
            var formSubmitFunctionEnd = ConnectWise.Utils.GetMethod(formSubmitFunctionName);
            var formSubmitFunction = formSubmitFunctionEnd(form);
            form.onSubmit(formSubmitFunction);
        }
        const formSuccessFunctionName = $form.data('onSuccess');
        if (formSuccessFunctionName) {
            let current = window.ConnectWise;
            formSuccessFunctionName.split('.').forEach((pathElement) => {
                if (pathElement === 'ConnectWise') {
                    return;
                }
                current = current[pathElement];
            });
            var formSuccessFunction = current(form);
            form.onSuccess(formSuccessFunction);
        }
        const formValidateFunctionName = $form.data('onValidate');
        if (formValidateFunctionName) {
            var validateFunction = ConnectWise.Utils.GetMethod(formValidateFunctionName);
            form.onValidate(validateFunction(form));
        }
        //Suppress Marketo submission logic on submit button click and add a new one.
        $form.find('[type=submit]').each(function () {
            $(this).unbind('click');
            $(this).bind('click', async function (e) {
                effect.lockForm();
                _GTMNotifyAttempSubmit($form);
                setUtmFields(form);
                e.preventDefault();
                var captcha = new Captcha(form);
                let valid = await captcha.valid();
                valid = valid && zoomInfo.Valid(form) && GeoFields.Valid(form) && form.validate();
                try {
                    await jqueryValidation.Validate(form);
                    //jquery validation passed, check the other validations
                    if (!valid) {
                        _GTMNotifySubmitFailure($form);
                        effect.unlockForm();
                        return;
                    }
                    let smsAuth = new SMSAuth();
                    await smsAuth.validate(form);
                    const submitFunctionName = $form.data('submitOverride');
                    await _setTrackingCookie(form).catch(() => { });
                    _GTMNotifySubmission($form);
                    if (submitFunctionName) {
                        var submitFunction = ConnectWise.Utils.GetMethod(submitFunctionName);
                        setTimeout(function () {
                            submitFunction(form);
                        }, 100);
                    }
                    else {
                        form.submit();
                    }
                }
                catch (e) {
                    _GTMNotifySubmitFailure($form);
                    effect.unlockForm();
                    return;
                }
            });
        });
    }
};
function parseLabel(labelText) {
    if (labelText.indexOf(':', labelText.length - 1) !== -1) {
        labelText = labelText.slice(0, -1);
    }
    var sepI = labelText.indexOf('##');
    if (sepI == -1) {
        return {
            label: labelText,
        };
    }
    else {
        return {
            label: labelText.slice(0, sepI),
            upText: labelText.slice(sepI + 2), //2 is the size of ##
        };
    }
}
let _setTrackingCookie = function (form) {
    var obj = form.getValues();
    let $form = $(form.getFormElem());
    if ($form.data('disableTracking') == true) {
        form.addHiddenFields({ _mkt_trk: '' });
        form.onSubmit(function (form) {
            form.vals({ _mkt_trk: '' });
        });
        return $.when(); //return a resolved promise
    }
    return $.ajax({
        type: 'POST',
        url: '/api/LeadManagement/UpdateMarketoLead',
        data: {
            jsonText: JSON.stringify(obj),
        },
    });
};
let _GTMNotifyAttempSubmit = function ($form) {
    window._mfq = window._mfq || [];
    window._mfq.push(['formSubmitAttempt', `#${$form.attr('id')}`]);
};
let _GTMNotifySubmission = function ($form) {
    window._mfq = window._mfq || [];
    window._mfq.push(['formSubmitSuccess', `#${$form.attr('id')}`]);
};
let _GTMNotifySubmitFailure = function ($form) {
    window._mfq = window._mfq || [];
    window._mfq.push(['formSubmitFailure', `#${$form.attr('id')}`]);
};
let addHiddenFieldsIfKnownVisitorForm = function (form) {
    var $form = $(form.getFormElem());
    var urlParams = ConnectWise.Utils.UrlParams();
    if ($form.find('.mktoNotYou').length > 0) {
        //it is known visitor form
        var hiddenFields = {
            GCLID__c: '',
            ga_cid__c: '',
            campaignCodeMostRecent: '',
            pageSource: '',
            utm_campaign: urlParams.utm_campaign,
            utm_medium: urlParams.utm_medium,
            utm_source: urlParams.utm_source,
            utm_term: urlParams.utm_term,
            utm_content: urlParams.utm_content,
            referringURL: '',
            mKTOProductInterest: '',
        };
        form.addHiddenFields(hiddenFields);
    }
};
function setUtmFields(form) {
    let utms = UTMHandler.GetUtmParametersFromSharedCookie();
    form.vals({
        utmcampaign: utms.utm_campaign,
        utmcontent: utms.utm_content,
        utmmedium: utms.utm_medium,
        utmterm: utms.utm_term,
        utmsource: utms.utm_source,
    });
}
function hideFields($form) {
    var fieldsToHide = $form.data("hiddenFields") || "";
    var fields = fieldsToHide.split(',');
    var effect = new FormEffects($form);
    fields.forEach(fieldName => {
        let $el = $form.find(`[name='${fieldName}']`);
        if ($el.length) {
            effect.hideRowNoAnimations($el);
            if (!$el.val() && $el.attr("aria-required") == "true")
                $el.val("********");
        }
    });
}
