﻿require('./vendor');

module.exports = {
    'Init': function () {
        ConnectWise.InitRecursive(ConnectWise, 'ConnectWise');
    },
    'InitRecursive': function (obj, path) {
        var keys = Object.keys(obj);

        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];

            if (obj.hasOwnProperty(key)) {
                var val = obj[key];

                if (key.toLowerCase() === 'init' && path !== 'ConnectWise' && val.length === 0) {
                    try {
                        val();
                    } catch (err) {
                        console.error(err);
                    }
                }

                if (val !== null && typeof val === 'object') {
                    ConnectWise.InitRecursive(val, path + '.' + key);
                }
            }
        }
    },
    'GlobalModules': require('./global-modules'),        
    'Utils': require('./utils')
};