import doSearchContent from './doSearchContent';
const initKeyFilters = () => {
    document.querySelectorAll('.js-key-filter').forEach((element) => {
        element.addEventListener('click', (event) => document.getElementById(element.textContent)?.click());
    });
    const keyFiltersToggleButton = document.querySelector('.js-key-filters-toggle');
    const keyFilters = document.querySelector('.js-key-filters');
    if (keyFiltersToggleButton && keyFilters) {
        keyFiltersToggleButton.addEventListener('click', (event) => {
            keyFilters.classList.toggle('expanded');
            if (keyFilters.classList.contains('expanded')) {
                keyFiltersToggleButton.innerHTML = `See Less <i class="fa-solid fa-minus"></i>`;
            }
            else {
                keyFiltersToggleButton.innerHTML = `See More <i class="fa-solid fa-plus"></i>`;
            }
        });
    }
};
const filterAccordionInit = () => {
    // get a list of all the general accordions and loop through them
    const accordionItemHeaders = document.querySelectorAll('.accordion-item-header');
    accordionItemHeaders.forEach((accordionItemHeader) => {
        // add the click event listener
        accordionItemHeader.addEventListener('click', (event) => {
            // find if there is an "active" accordion header... remove the active class
            const currentlyActiveAccordionItemHeader = document.querySelector('.accordion-item-header.active');
            if (currentlyActiveAccordionItemHeader && currentlyActiveAccordionItemHeader !== accordionItemHeader) {
                currentlyActiveAccordionItemHeader.classList.toggle('active');
                currentlyActiveAccordionItemHeader.nextElementSibling.style.maxHeight = 0;
            }
            // after that, add the active class to the clicked accordion
            accordionItemHeader.classList.toggle('active');
            // finally, show the accordion's content
            const accordionItemBody = accordionItemHeader.nextElementSibling;
            if (accordionItemHeader.classList.contains('active')) {
                accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight / 16 + 'rem';
            }
            else {
                accordionItemBody.style.maxHeight = 0;
            }
        });
    });
};
const initFilters = (elements, searchSettings) => {
    const { filtersContainer, showFilters, hideFilters, selectedFilters, filterReset, filterCheckboxes } = elements;
    const toggleFilters = function (open) {
        filtersContainer.classList.toggle('open', open);
        if (!open) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    if (showFilters && filtersContainer) {
        showFilters.addEventListener('click', (event) => {
            toggleFilters(true);
        });
    }
    if (hideFilters && filtersContainer) {
        hideFilters.addEventListener('click', (event) => {
            toggleFilters(false);
        });
    }
    const removeFilterClickHandler = function (event) {
        const element = event.currentTarget;
        selectedFilters.removeChild(element.parentElement);
        if (selectedFilters.querySelectorAll('.js-selected-filter-remove').length == 0) {
            filterReset.parentElement.style.display = 'none';
        }
        const dataFacetValueKey = element.getAttribute('data-facet-value-key');
        const filterCheckbox = document.querySelector(`#${dataFacetValueKey}`);
        if (filterCheckbox) {
            filterCheckbox.checked = false;
            if (filterCheckbox.classList.contains('js-checkbox-key')) {
                const keyFilterListItem = document.querySelector(`[data-search-key='${filterCheckbox.id}']`);
                if (keyFilterListItem) {
                    keyFilterListItem.classList.toggle('checked');
                }
            }
        }
        doSearchContent(1, elements, searchSettings);
    };
    if (filterCheckboxes && filterReset) {
        filterCheckboxes.forEach((element) => {
            element.addEventListener('click', (event) => {
                const keyFilterListItem = document.querySelector(`[data-search-key='${element.id}']`);
                if (element.checked) {
                    if (keyFilterListItem) {
                        keyFilterListItem.classList.toggle('checked', true);
                    }
                    var newSelectedFilter = document.createElement('li');
                    if (element.classList.contains('js-checkbox-key')) {
                        newSelectedFilter.innerHTML = `${element.getAttribute('data-title')}<button class="close js-selected-filter-remove js-selected-key-remove" data-facet-value-key="${element.value}"><i class="fas fa-close"></i></button>`;
                    }
                    else {
                        newSelectedFilter.innerHTML = `${element.getAttribute('data-title')}<button class="close js-selected-filter-remove" data-facet-value-key="${element.value}"><i class="fas fa-close"></i></button>`;
                    }
                    selectedFilters.insertBefore(newSelectedFilter, filterReset.parentElement);
                    newSelectedFilter.querySelector('.js-selected-filter-remove').addEventListener('click', removeFilterClickHandler);
                    if (filterReset.parentElement.style.display == 'none') {
                        filterReset.parentElement.style.display = '';
                    }
                }
                else {
                    if (keyFilterListItem) {
                        keyFilterListItem.classList.toggle('checked', false);
                    }
                    const selectedFilter = document.querySelector(`[data-facet-value-key="${element.value}"]`);
                    if (selectedFilter) {
                        selectedFilter.removeEventListener('click', removeFilterClickHandler);
                        selectedFilters.removeChild(selectedFilter.parentElement);
                        if (selectedFilters.querySelectorAll('.js-selected-filter-remove').length == 0) {
                            filterReset.parentElement.style.display = 'none';
                        }
                    }
                }
                doSearchContent(1, elements, searchSettings);
            });
        });
        filterReset.addEventListener('click', (event) => {
            filterCheckboxes.forEach((element) => {
                element.checked = false;
                const keyFilterListItem = document.querySelector(`[data-search-key='${element.id}']`);
                if (keyFilterListItem) {
                    keyFilterListItem.classList.toggle('checked', false);
                }
            });
            selectedFilters.querySelectorAll('.js-selected-filter-remove').forEach((element) => {
                element.removeEventListener('click', removeFilterClickHandler);
                selectedFilters.removeChild(element.parentElement);
            });
            filterReset.parentElement.style.display = 'none';
            doSearchContent(1, elements, searchSettings);
        });
    }
    filterAccordionInit();
    initKeyFilters();
};
export default initFilters;
