import './easyResponsiveTabs.js';
export function initResponsiveTabs() {
    $('.tab-set').each(function () {
        $(this).easyResponsiveTabs({
            type: 'default',
            width: 'auto',
            closed: 'accordion',
            fit: true,
            tabidentify: $(this).attr('data-tab-id'), // The tab groups identifier
        });
    });
}
export function tabcordionInit() {
    window.addEventListener('load', function () {
        //Horizontal Tab
        initResponsiveTabs();
    });
}
