﻿module.exports = function (url, callback, callbackError) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                if (callback) {
                    callback();
                }
            }
        };
    } else {
        script.onload = function () {
            if (callback) {
                callback();
            }
        };
    }
    if (callbackError) {
        script.onerror = callbackError;
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};