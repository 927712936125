import moment from 'moment';
export function eventScheduleFormInit() {
    const eventScheduleForms = document.querySelectorAll('.event-schedule-form');
    if (eventScheduleForms) {
        //Get current timezone
        let currentTimezoneOffset = moment().utcOffset();
        eventScheduleForms.forEach((e) => {
            const timezoneSelectionContainer = e.querySelector('.event-schedule-form__tz-selection-container');
            const changeTimezoneLink = e.querySelector('.event-schedule-form__change-tz-link');
            const timezoneSelection = e.querySelector('.event-schedule-form__tz-select-list');
            const availableTimes = e.querySelectorAll('.event-schedule-form__available-time-item');
            const eventScheduleFormSubmitButton = e.querySelector('.event-schedule-form__submit-btn');
            const updateWebinarTime = function () {
                let selectedTimezoneOption = timezoneSelection.selectedOptions[0] ?? timezoneSelection.options[0];
                timezoneSelection.value = selectedTimezoneOption?.value;
                let timezone = selectedTimezoneOption?.text;
                changeTimezoneLink.innerHTML = timezone;
                if (availableTimes) {
                    availableTimes.forEach((timeSelection) => {
                        var radioButton = timeSelection.querySelector('input');
                        var label = timeSelection.querySelector('label');
                        var utcDate = moment(radioButton.getAttribute('data-datetime-iso'));
                        var realTime = utcDate.add(parseInt(selectedTimezoneOption?.value ?? 0) - utcDate.utcOffset() * 60, 'seconds');
                        var displayTime = `${realTime.format('dddd, MMMM DD yyyy hh:mm A')} (${selectedTimezoneOption.getAttribute('data-timezone-abbreviation')})`;
                        label.innerHTML = displayTime;
                        radioButton.value = displayTime;
                    });
                }
            };
            if (timezoneSelectionContainer && changeTimezoneLink && timezoneSelection) {
                timezoneSelection.value = currentTimezoneOffset * 60;
                updateWebinarTime();
                const selectTimezone = timezoneSelectionContainer.querySelector('.event-schedule-form__confirm-tz-btn');
                const cancelTimezone = timezoneSelectionContainer.querySelector('.event-schedule-form__cancel-tz-btn');
                changeTimezoneLink.addEventListener('click', (event) => {
                    event.preventDefault();
                    timezoneSelectionContainer.classList.toggle('active', true);
                });
                if (cancelTimezone) {
                    cancelTimezone.addEventListener('click', (event) => {
                        event.preventDefault();
                        timezoneSelectionContainer.classList.toggle('active', false);
                        return false;
                    });
                }
                if (selectTimezone) {
                    selectTimezone.addEventListener('click', (event) => {
                        event.preventDefault();
                        updateWebinarTime();
                        timezoneSelectionContainer.classList.toggle('active', false);
                        return false;
                    });
                }
                let form = e.querySelector('form');
                if (eventScheduleFormSubmitButton && form) {
                    eventScheduleFormSubmitButton.addEventListener('click', (event) => {
                        event.preventDefault();
                        let selectedTimezoneValue = (timezoneSelection.selectedOptions[0] ?? timezoneSelection.options[0])?.text;
                        let selectedTimeRadioButton = e.querySelector('input[type="radio"]:checked');
                        $('#liveDemoTimezone').val(selectedTimezoneValue);
                        $('#liveDemoDateTime').val(selectedTimeRadioButton?.value);
                        $('#webinarTimeZone').val(selectedTimezoneValue);
                        $('#webinarDateTime').val(selectedTimeRadioButton?.value);
                        $("input[name='cWWebinarEventTitle']").val(selectedTimeRadioButton?.getAttribute('data-webinar-subject'));
                        $("input[name='_webinarID']").val(selectedTimeRadioButton?.getAttribute('data-webinar-id'));
                        window.mktoInitValues = window.mktoInitValues || {};
                        window.mktoInitValues['liveDemoTimezone'] = selectedTimezoneValue;
                        window.mktoInitValues['liveDemoDateTime'] = selectedTimeRadioButton?.value;
                        window.mktoInitValues['webinarTimeZone'] = selectedTimezoneValue;
                        window.mktoInitValues['webinarDateTime'] = selectedTimeRadioButton?.value;
                        if (ConnectWise.GlobalModules.Marketo.FormSuccess.MainSubmitForm) {
                            const successFunction = ConnectWise.GlobalModules.Marketo.FormSuccess.MainSubmitForm(form);
                            successFunction();
                        }
                        return false;
                    });
                }
            }
        });
    }
}
