import { Utils } from '../../utils';
import { UserManager } from 'oidc-client';
export class MultistepTrialForm {
    form;
    $form;
    $container;
    constructor(form) {
        this.form = form;
        this.$form = $(form.getFormElem());
        this.$container = this.$form.parents('.trial-form');
    }
    getCurrentForm() {
        return this.form;
    }
    getCurrentFormInputField(fieldName) {
        let $input = this.$form.find(`input[name='${fieldName}']`);
        return $input.length > 0 ? $input[0] : null;
    }
    isLastForm() {
        return (this.getActiveFormIndex() + 1) == this.getNumberOfForms();
    }
    isFirstForm() {
        return this.getActiveFormIndex() === 0;
    }
    isEmailVerificationForm() {
        return this.$form.data("emailVerification") === 'true';
    }
    getNumberOfForms() {
        return this.$container.find('.trial-step-form').length;
    }
    getActiveFormIndex() {
        //Get Current Form
        let $activeFormDiv = this.$container.find('.trial-step-form.active');
        let $allFormDivs = this.$container.find('.trial-step-form');
        //Get position of the active form
        let index = $allFormDivs.index($activeFormDiv);
        return index;
    }
    processTrialCreationResult(result) {
        this.form.setValues({ TrialCreated: result.success ? 'true' : 'false' });
        if (result.formData) {
            this.form.setValues(result.formData);
            this.storeFormValues();
        }
        this.form.onSuccess(() => {
            if (result.sso) {
                const oidcUri = new URL(result.sso.oidcUrl, window.location.origin);
                const userManager = new UserManager({
                    client_id: result.sso.clientId,
                    redirect_uri: oidcUri.href,
                    response_type: 'id_token token',
                    scope: 'openid email api.productinstances api.provisioning',
                    authority: result.sso.authUrl
                });
                let formData = this.getDataFromAllForms();
                userManager.signinRedirect({
                    state: {
                        formData,
                        postbackUrl: result.sso.postbackUrl,
                    },
                    login_hint: formData.Email,
                });
            }
            else if (result.redirectUrl) {
                window.location.href = result.redirectUrl;
            }
            else if (result.displayHtmlContent) {
                let $el = $(this.$form.parent());
                $el.fadeOut(500, () => {
                    $el.html(result.displayHtmlContent);
                    $el.fadeIn(500);
                });
            }
            return false;
        });
        this.form.submit();
    }
    formDataToObject(strFormData, result) {
        var str = strFormData;
        var r = result || {};
        str.split('&').forEach((value) => {
            let keyValue = value.split("=");
            if (keyValue.length == 2 && keyValue[1]) {
                r[keyValue[0]] = decodeURIComponent(keyValue[1]);
            }
        });
        return r;
    }
    serializeToFormData(obj) {
        if (!obj)
            return "";
        var r = "";
        for (const property in obj) {
            r += property + "=" + encodeURIComponent(obj[property]) + "&";
        }
        if (r.length > 0 && r[r.length - 1] == '&')
            r = r.slice(0, -1);
        return r;
    }
    getComponentId() {
        return this.$container.data("contentId");
    }
    numberOfVisibleFieldsInCurrentForm() {
        let acum = 0;
        this.$form.find("input,select,textarea").each((i, el) => {
            if ($(el).is(":visible"))
                acum++;
        });
        return acum;
    }
    getDataFromAllForms() {
        let data = this.$container.data('formValues');
        if (!data) {
            //initialize data with the server state
            let state = window[`ServerState${this.getComponentId()}`] || {};
            state['__RequestVerificationToken'] = this.$container.find("input[name='__RequestVerificationToken']").val();
            state["contentId"] = this.getComponentId();
            state["formSubmissionURLPathName"] = window.location.origin + window.location.pathname;
            state["pageUrl"] = window.location.href;
            state["EmailToken"] = Utils.GetQueryParams()["EmailToken"] || "";
            data = state;
            this.$container.data('formValues', data);
        }
        return data;
    }
    mergeWithState(obj) {
        let data = this.getDataFromAllForms();
        data = $.extend(data, obj);
        this.$container.data('formValues', data);
    }
    getPostDataFromAllForms() {
        var test = this.serializeToFormData(this.getDataFromAllForms());
        return test;
    }
    initCurrentFormValues() {
        //copy preveous set email to the next step form
        let formValues = this.getDataFromAllForms();
        this.$form.find('input').each((i, elem) => {
            let input = elem;
            if (formValues[input.name] && !input.value) {
                $(input).val(formValues[input.name]);
            }
        });
    }
    storeFormValues() {
        //copy preveous set email to the next step form
        let formValues = this.getDataFromAllForms();
        this.$form.find('input').each((i, elem) => {
            let input = elem;
            if (formValues[input.name] && !input.value) {
                $(input).val(formValues[input.name]);
            }
        });
        if (formValues.Email)
            this.form.setValues({ Email: formValues.Email });
        //merge  current form values with  the container state
        //remmber to not override data in formValues with null or empty strings
        formValues = $.extend(formValues, Utils.ObjectProcess.removeNullEmptyProperties({ ...this.form.getValues() }));
        this.$container.data('formValues', { ...formValues });
    }
    submitAndGoToNextForm() {
        return new Promise((resolve, reject) => {
            this.storeFormValues();
            //Get Current Form Position
            let $activeFormDiv = this.$container.find('.trial-step-form.active');
            let $allFormDivs = this.$container.find('.trial-step-form');
            //Get position of the active form
            let index = this.getActiveFormIndex();
            this.form.onSuccess(() => {
                if (this.isLastForm()) {
                    resolve(null); //is last index?
                }
                else {
                    let $nextFormDiv = $($allFormDivs[index + 1]);
                    $activeFormDiv.removeClass('active');
                    $nextFormDiv.addClass('active');
                    resolve($nextFormDiv.find("form"));
                }
                return false; //do not let marketo form 3rd party code to redirect to anything.
            });
            this.form.submit();
        });
    }
}
