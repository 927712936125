const getSearchSettings = (elements) => {
    const searchApi = elements.searchResultsBlock.getAttribute('data-api-search-url');
    const resultBlockId = elements.searchResultsBlock.getAttribute('data-result-block-id');
    const scopeId = elements.searchResultsBlock.getAttribute('data-scope-id');
    const pageSize = elements.searchResultsBlock.getAttribute('data-page-size');
    const barSize = elements.searchResultsBlock.getAttribute('data-bar-size');
    const currentPageId = elements.searchResultsBlock.getAttribute('data-current-page-id');
    return {
        searchApi,
        resultBlockId,
        scopeId,
        pageSize,
        currentPageId,
        barSize
    };
};
export default getSearchSettings;
