module.exports = function () {

    var setError = function ($input, errorMessage) {
        if ($input && $input.length) {
            $row = $input.hasClass("mktoFormRow") ? $input : $input.parents(".mktoFormRow");
            if ($row.length) {
                $row.addClass("error");
                $inputElem = $row.find("input,select")

                $inputElem.after(`<label for="${$inputElem[0].name}" class="error">${errorMessage}</label>`)
            }
        }
    }

    var clearError = function ($input, errorMessage) {
        if ($input && $input.length) {
            $row = $input.hasClass(".mktoFormRow") ? $input : $input.parents(".mktoFormRow");
            if ($row.length) {
                $row.removeClass("error");
                $labelElem = $row.find("label.error")
                $labelElem.remove();
            }
        }
    }

    return {
        clearError,
        setError
    };

}()