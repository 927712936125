var Validation = require('./validation');
import { FormEffects } from './form-effects';
export class GeoFields {
    static Init(form) {
        let showCountry = ConnectWise.Utils.GetUrlParameters()["showCountry"];
        if (showCountry) {
            window.geoIPCountry = window.geoIPState = window.geoIPZip = null;
        }
        let $form = $(form.getFormElem());
        let $country = GeoFields._countryField($form);
        let effects = new FormEffects($form);
        if ($country.length) {
            if (window.geoIPCountry && $country.find(`option[value='${window.geoIPCountry}']`).length) {
                form.setValues({ Country: window.geoIPCountry });
                effects.hideRowNoAnimations($country);
            }
            else {
                effects.showRow($country);
            }
        }
        var countryValue = window.geoIPCountry || $country.val();
        //States dropdowns
        let $state = GeoFields._stateField($form);
        if ($state.length) { //state hidden field exists
            if (window.geoIPState) {
                form.setValues({ State: window.geoIPState });
            }
            else { //no geoip info render state dropdowns
                GeoFields._renderStateDropdowns(form);
                GeoFields._updateStateDropdowns($form, countryValue);
            }
        }
        //Zip code
        let $zip = GeoFields._zipField($form);
        if ($zip.length) {
            if (window.geoIPZip) {
                form.setValues({ PostalCode: window.geoIPZip }); //set zip code
                effects.hideRowNoAnimations($zip); //hide zip code
            }
            else {
                if (!(countryValue == "US" || countryValue == "CA" || countryValue == 'AU')) {
                    effects.hideRowNoAnimations($zip);
                }
                $country.on("change", (e) => {
                    GeoFields._updateZip($form, e.target.value);
                });
            }
            $zip.on("change", (ev) => {
                if ($zip.val())
                    Validation.clearError($zip);
            });
        }
    }
    static _removeGeoLocErrors($form) {
        $form.find(".geoLoc,input[name='PostalCode']").each((i, e) => Validation.clearError($(e)));
    }
    static _stateField($form) {
        return $form.find("input[name='State']");
    }
    static _zipField($form) {
        return $form.find("input[name='PostalCode']");
    }
    static _countryField($form) {
        return $form.find('select[name="Country"]');
    }
    static _updateZip($form, countryValue) {
        let $zip = GeoFields._zipField($form);
        let effects = new FormEffects($form);
        if (countryValue == "US" || countryValue === "CA" || countryValue == 'AU') {
            effects.showRow($zip);
        }
        else
            effects.hideRow($zip);
    }
    static _updateStateDropdowns($form, countryValue) {
        let $state = GeoFields._stateField($form);
        let effects = new FormEffects($form);
        $form.find(".geoloc").addClass("mktoFormRowHidden");
        $form.find(".geoloc").removeClass("geoloc-state-active");
        switch (countryValue) {
            case 'US':
                {
                    effects.showRow($form.find("#usDropdown"));
                    $form.find("#usDropdown").addClass("geoloc-state-active");
                    break;
                }
            case 'CA':
                {
                    effects.showRow($form.find("#caDropdown"));
                    $form.find("#caDropdown").addClass("geoloc-state-active");
                    break;
                }
            case 'AU':
                {
                    effects.showRow($form.find("#auDropdown"));
                    $form.find("#auDropdown").addClass("geoloc-state-active");
                    break;
                }
            default:
                {
                    $state.val("");
                }
        }
    }
    static _renderStateDropdowns(form) {
        let $form = $(form.getFormElem());
        if ($form.find("#usDropdown").length) {
            return; //States already rendered
        }
        let $country = GeoFields._countryField($form);
        $country.parents(".mktoFormRow").after(`
                       <div id="usDropdown" class= "geoloc mktoFormRow mktoFormRowHidden" > 
                        <div class="mktoFormCol">
                        <div class="mktoFieldWrap mktoRequiredField">
                        <select  name="USState" class="mktoField mktoHasWidth mktoRequired" aria-required="true">
                        <option value="">State</option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                        </select>
                        </div>
                        </div>
                        </div>
                        <div id="caDropdown" class= "geoloc mktoFormRow mktoFormRowHidden hidden" > 
                        <div class="mktoFormCol">
                        <div class="mktoFieldWrap mktoRequiredField">
                        <select id="State" name="CAState" class="mktoField mktoHasWidth mktoRequired" aria-required="true">
                        <option value="">Province</option>
                        <option value="AB">Alberta</option>
                         <option value="BC">British Columbia</option>
                         <option value="MB">Manitoba</option>
                         <option value="NB">New Brunswick</option>
                         <option value="NL">Newfoundland and Labrador</option>
                         <option value="NS">Nova Scotia</option>
                         <option value="ON">Ontario</option>
                         <option value="PE">Prince Edward Island</option>
                         <option value="QC">Quebec</option>
                         <option value="SK">Saskatchewan</option>
                         <option value="NT">Northwest Territories</option>
                         <option value="NU">Nunavut</option>
                         <option value="YT">Yukon</option>
                        </select>
                        </div></div></div>
                        <div id="auDropdown" class= "geoloc mktoFormRow mktoFormRowHidden hidden" > 
                        <div class="mktoFormCol">
                        <div class="mktoFieldWrap mktoRequiredField">
                            <select id="State" name="AUState" class="mktoField mktoHasWidth mktoRequired" aria-required="true">
                                <option value="">State</option>
                                <option value="ACT">Australian Capital Territory</option>
                                <option value="NSW">New South Wales</option>
                                <option value="NT">Northern Territory</option>
                                <option value="QLD">Queensland</option>
                                <option value="SA">South Australia</option>
                                <option value="TAS">Tasmania</option>
                                <option value="VIC">Victoria</option>
                                <option value="WA">Western Australia</option>
                            </select>
                        </div></div></div>`); //end of after
        $country.on("change", function (e) {
            GeoFields._updateStateDropdowns($form, e.target.value);
        });
        $form.find(".geoloc").on("change", function (e) {
            form.setValues({ State: e.target.value });
        });
    }
    static Valid(form) {
        let $form = $(form.getFormElem());
        GeoFields._removeGeoLocErrors($form);
        let effects = new FormEffects($form);
        var result = true;
        $form.find(".geoloc-state-active").each((i, e) => {
            if (!$(e).find("select").val()) {
                Validation.setError($(e), "Required field");
                result = false;
            }
        });
        let $zip = $form.find("input[name='PostalCode']");
        if ($zip.length && !effects.isHidden($zip)) {
            if (!$zip.val()) {
                Validation.setError($zip, "Required field");
                result = false;
            }
        }
        return result;
    }
}
