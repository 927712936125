export function timerInit() {
    $(document).ready(function () {
        var $progressBar = $('#progress-bar');
        if ($progressBar.length === 0)
            return;
        var $timerTicking = $(".timer--ticking");
        var $timerCompleted = $(".timer--progess-bar-completed");
        var $progressContainer = $('#progress-container');
        var width = 0;
        var duration = parseInt($progressContainer.data("duration") || "0");
        var interval = duration * 1000 / 100; // 60 seconds divided by 100 increments
        var progressInterval = setInterval(function () {
            if (width >= 100) {
                clearInterval(progressInterval);
                setTimeout(() => {
                    $timerTicking.fadeOut(800, function () {
                        $timerCompleted.fadeIn(800);
                    });
                }, 2000);
            }
            else {
                width++;
                $progressBar.css('width', width + '%');
                $progressBar.text(width + '%');
            }
        }, interval);
        $(".timer--progess-bar-completed").each((i, el) => {
            let link = $(el).data("redirectLink");
            if (link) {
                $(el).find("a, button").on("click", function (e) {
                    e.preventDefault();
                    window.location.href = link;
                });
            }
        });
    });
}
