﻿module.exports = function () {
    function Init() {
        let result = false;
        $("form").each((i, form) => {
            if ($(form).data("driftCampaign")) {
                result = true;
            }
        })

        if (result && !$(`#mktoForm_2435`).length) //form with a campaign was found, load drift forms
        {
            $("body").append(`
        <div id="driftForms" style="display:none">
            <form  data-form-id="2435"></form>
            <form  data-form-id="2444"></form>
            <form  data-form-id="2452"></form>
        </div>`)

          
        }
    }





    return {
        Init
    }
}()