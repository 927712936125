﻿module.exports = {

    _submitDemoSignup: function () {
        $.post(window.location.pathname + '/completegate',
            function (data) {
                let url = null;
                if (data.type === 'redirect') {

                    url = data.url;
                }
                else if (data.type === 'content') {
                    $('.demo-signup-page').html(data.content);
                    ConnectWise.Utils.Reinit();
                }
                ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, url);
            });
    },
    _submitWebinarSignup: function () {
        $.post(window.location.pathname + '/completegate',
            function (data) {
                if (data.type === 'content') {
                    $('.webinar-detail-page').html(data.content);
                    ConnectWise.Utils.Reinit();
                }
            });

    },
    _submitLiveDemoSignup: function () {
        $.post(window.location.pathname + '/completegate',
            function (data) {
                let url = null;
                if (data.type === 'redirect') {

                    url = data.url;
                }
                else if (data.type === 'content') {
                    $('.demo-signup-page').html(data.content);
                    ConnectWise.Utils.Reinit();
                }
                ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, url);
            });
    },

    _swapVisibileTire: function () {
        const $form = $(form.getFormElem());

        const $formCompleteContainer = $form.parent().find('.event-detail-page__form-complete-container').first();
        $formCompleteContainer.addClass('event-detail-page__form-complete-container--visible');

        $form.remove();

        const $originalTitle = $formCompleteContainer.siblings('h3').first();
        $originalTitle.remove();

        return false;
    },
    _swapDriftChat: function () {
        const $form = $(form.getFormElem());
        ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values);
        return false;
    },
    _mainSubmit: function () {
        $.post(window.location.pathname + '/completegate',
            function (data) {
                if (data.type === 'redirect') {
                    ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, data.url);
                }
                else if (data.type === 'content') {
                    $('#main').html(data.content);
                    ConnectWise.Utils.Reinit();
                    window.scrollTo({ top: 0, behavior: "auto" });
                    ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values);
                }
            });

        return false;
    },

    MainSubmitForm: function (form) {
        return function (values, followUpUrl) {
            const $form = typeof form.getFormElem === 'function' ? $(form.getFormElem()) : $(form);

            var submitAction = $form.data("form-submit-action");
            if (!submitAction)
                return false;

            // switch (submitAction) {
            //     case "webinar":
            //         submitWebinar($form);
            //         break;
            //     case "Live-Demo":
            //         break;
            // }
            
            $.post('/api/LeadManagement/SubmitAction',{submitAction},
                function (data) {
                    let url = null;
                    //ConnectWise.Utils.Cookie.set("Identified", "true", 365, null);

                    if (data.type === 'redirect') {
                        url = data.url;
                        if(data.enableChat){
                            ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, url);
                        }
                        else if(url){
                            window.location = url
                        }
                    }
                    else if (data.type === 'releaseAction') {
                        if(data.content)
                        {
                            $form.parent('.marketo-form').html(data.content);
                        }
                        
                        if(data.enableChat){
                            ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, url);
                        }
                    }
                    else if (data.type === 'nextAction') {
                        var $nextElem = $form.parents('.wizard-step-item')?.next('.wizard-step-item');
                        if($nextElem){
                            $form.parents('.wizard-step-item').toggleClass('active', false);
                            $nextElem.toggleClass('active', true);                            
                        }

                        if (data.enableChat) {
                            ConnectWise.GlobalModules.Marketo.DriftRedirect($form, values, url);
                        }
                    }
                });

            

            return false;
        }
    }
};