﻿var driftForm = function (formId, valueToSet,url) {
    let driftForm = null;
    try {
        MktoForms2 = MktoForms2 || {};

        driftForm = MktoForms2.getForm(formId);
    }
    catch (error) {
        console.log("Drift error cannot find form "+formId)
    }
    if (driftForm) {
        $driftForm = $(driftForm.getFormElem())
        if ($driftForm.attr("data-form-submitted"))
            return;
        driftForm.setValues(valueToSet);
        driftForm.onSuccess(() => {
            if (url) {
                window.location = url;
            }
            return false;
        })
        driftForm.submit()
        $driftForm.attr("data-form-submitted", true);
    }
}



module.exports = function ($form, formValues, followUpUrl) {
    var url = followUpUrl ? followUpUrl : $form.data("confirmationPage");
    let redirectionHandler = null;
    ConnectWise.GlobalModules.Marketo.Effects.lockForm($form);
    //drift
    var campaign = $form.data("driftCampaign");
    if (campaign) {
        drift.on('ready', function (api, eventData) {
            redirectionHandler = setTimeout(() => {
                if (url)
                    window.location = url;
                else
                    ConnectWise.GlobalModules.Marketo.Effects.unlockForm($form);

            }, 1000)
            // eventData content described on the "Configuration and Settings" page.
            window.drift.on('chatClose', function (data) {
                console.log("Close data")
                console.log(data)
                driftForm(2444, {
                    "driftChatClosed": true
                }, url)
            })

            window.drift.on('chatOpen', function () {
                if (redirectionHandler) {
                    clearTimeout(redirectionHandler)
                }
                ConnectWise.GlobalModules.Marketo.Effects.unlockForm($form);
            })

            window.drift.on("scheduling:meetingBooked", function (data) {
                driftForm(2452, {
                    "driftChatBookMeeting": true
                })
            });

            window.drift.on("conversation:buttonClicked", function (data) {
                if (data.buttonBody && data.buttonBody.toLowerCase().indexOf("chat") !== -1) {
                    driftForm(2435, {
                        "driftChatStarted": true
                    })
                }
            })


            

            window.drift.on("startConversation", function (data) {
                console.log("User started a new conversation " + JSON.stringify(data))
            })
         
            window.drift.on("gdprClicked", function (data) {
                console.log("GDPR button clicked: " + JSON.stringify(data))
            })
            window.drift.on("campaign:open", function (data) {
                console.log("User opened campaign", JSON.stringify(data))
            })
            window.drift.on("campaign:click", function (data) {
                console.log("User clicked campaign", JSON.stringify(data))
            })
            window.drift.on("campaign:dismiss", function (data) {
                console.log("User dismissed campaign", JSON.stringify(data))
            })
            window.drift.on("campaign:submit", function (data) {
                console.log("User submitted campaign", JSON.stringify(data))
            })
           
            window.drift.on("conversation:playbookFired", function (data) {
                console.log("Playbook fired: " + JSON.stringify(data))
            })
            window.drift.on("phoneCapture", function (data) {
                console.log("User provided a phone number: " + JSON.stringify(data))
            })
            window.drift.on("conversation:firstInteraction", function (data) {
                console.log("First interaction: " + JSON.stringify(data))
            })
            window.drift.on('welcomeMessage:open', function () {
                console.log('Welcome Message is open 🌞')
            })
            window.drift.on('welcomeMessage:close', function () {
                console.log('Welcome Message is closed 🌞')
            })
            window.drift.on("emailCapture", function (e) {
                console.log("user identified as: " + e.data.email)
            })           
        })
        drift.api.collectFormData(formValues, {
            campaignId: campaign
        });
        return;
    }
    else
    {
        if (url) {
            window.location = url;
        }
        ConnectWise.GlobalModules.Marketo.Effects.unlockForm($form);
    }

}