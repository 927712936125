import { Utils } from 'Presentation/js/utils';
import { FormEffects } from 'Presentation/js/connectwise/global-modules/marketo/form-effects';
export class SMSAuth {
    codeInput;
    getSMSCodeInput() {
        return $(".sms-modal #smsCode")[0];
    }
    validate(form) {
        return new Promise((resolve, reject) => {
            let $form = $(form.getFormElem());
            if (!$form.data('smsAuth')) { //sms disabled, proceed
                resolve(true);
                return;
            }
            this.displayForm(form, resolve, reject);
        });
    }
    ;
    setFormPhone($form, phone) {
        if (phone) {
            var match = phone.match(/^\(\+([ 0-9]{1,3})\)([ 0-9]{1,15})/);
            if (match && match.length === 3) {
                $(".sms-modal .sms-authentication__body #countryCode").val(match[1]);
                $(".sms-modal .sms-authentication__body #phoneNumber").val(match[2]);
                $(".sms-modal .sms-authentication__body #phoneNumberVar").text(`(+${match[1]}) ${match[2]}`);
                $form.find("#Phone").val(phone);
                // sendSMS($form);
            }
            else
                this.setError("Invalid Phone Number");
        }
    }
    getFormPhone($form) {
        return $form.find("#Phone").val();
    }
    displayForm(form, resolve, reject) {
        let $form = $(form.getFormElem());
        let phone = this.getFormPhone($form);
        let id = $form.data("formId");
        let $confirmModal = $(`#confirmModal_${id}`);
        this.codeInput = $("#smsCode")[0];
        let modalHtml = $confirmModal.html();
        modalHtml = modalHtml.replace("${Phone}", `<span id="phoneNumberVar"></span>`);
        $confirmModal.html(modalHtml);
        this.setFormPhone($form, phone);
        let timestamp = "modalNotification" + new Date().getTime();
        $("body").append(`<a class="${timestamp}" style="display:none"></a>`);
        let $modalTrigger = $("." + timestamp);
        //popup windows
        $modalTrigger.modaal({
            type: 'inline',
            custom_class: 'sms-modal',
            content_source: `#confirmModal_${id}`,
            overlay_close: false,
            width: 600,
            after_open: () => {
                this.setFormPhone($form, phone);
                this.setModalEvents($form, $modalTrigger, resolve, reject);
                this.initCaptcha($confirmModal);
            },
            before_close: () => {
                this.clear();
                this.enablePanel1();
            }
        });
        $modalTrigger.modaal('open');
    }
    disableButton() {
        $("#sendCode").prop("disabled", true);
    }
    enableButton() {
        $("#sendCode").prop("disabled", false);
    }
    initCaptcha($confirmModal) {
        if (this.isTurnstileEnabled()) {
            this.initTurnstile($confirmModal);
        }
        else if (this.isRecaptchaEnabled()) {
            this.initReCaptcha($confirmModal);
        }
    }
    initTurnstile($confirmModal) {
        let $captcha = $(".sms-modal .sms-captcha");
        let key = $captcha.data("siteKey");
        this.disableButton();
        let widgetId = window.turnstile.render($captcha[0], {
            sitekey: key,
            size: "flexible",
            callback: (token) => {
                console.log(`Challenge Success ${token}`);
                $captcha.data({ token });
                this.enableButton();
            },
            "expired-callback": () => {
                this.disableButton();
            },
            "before-interactive-callback": function () {
                console.log("Interaction Requested");
            },
            "after-interactive-callback": function () {
                console.log("Interaction Requested");
            },
            "timeout-callback": function () {
                console.log("timeout callback");
            }
        });
        $captcha.data({ widgetId });
    }
    initReCaptcha($confirmModal) {
        let $captcha = $(".sms-modal .sms-captcha");
        let key = $captcha.data("siteKey");
        this.disableButton();
        let widgetId = globalThis.grecaptcha.render($captcha[0], {
            sitekey: key,
            theme: "light",
            // size: "normal",
            callback: (token) => {
                console.log(`Challenge Success ${token}`);
                $captcha.data({ token });
                this.enableButton();
            },
            "expired-callback": () => {
                this.disableButton();
            },
            "error-callback": function () {
                console.log("Error happened, cant run captcha");
            }
        });
        $captcha.data({ widgetId });
    }
    isTurnstileEnabled() {
        let $captcha = $(".sms-modal .sms-captcha");
        return ($captcha.data("captchaSolution") === "turnstile");
    }
    isRecaptchaEnabled() {
        let $captcha = $(".sms-modal .sms-captcha");
        return ($captcha.data("captchaSolution") === "recaptcha");
    }
    isCaptchaEnabled() {
        let $captcha = $(".sms-modal .sms-captcha");
        return ($captcha.data("captchaSolution") !== "none");
    }
    resetCaptcha() {
        if (this.isCaptchaEnabled()) {
            this.disableButton();
            let $captcha = $(".sms-modal .sms-captcha");
            if (this.isTurnstileEnabled())
                window.turnstile.reset($captcha.data("widgetId"));
            else if (this.isRecaptchaEnabled()) {
                window.grecaptcha.reset($captcha.data("widgetId"));
            }
        }
    }
    setError(error) {
        this.clear();
        $(".sms-modal .sms-authentication__evaluation-result").text(error);
        $(".sms-modal .sms-authentication__body").addClass("error");
    }
    isError() {
        return $(".sms-modal .sms-authentication__body.error").length > 0;
    }
    setCaptchaError() {
        this.clear();
        $(".sms-modal .sms-authentication__evaluation-result").text("Please solve captcha");
        $(".sms-modal .sms-authentication__body").addClass("error");
        $(".sms-modal .sms-captcha").addClass("sms-captcha-error");
    }
    clear() {
        this.getSMSCodeInput().readonly(false);
        $(".sms-modal .sms-captcha").removeClass("sms-captcha-error");
        $(".sms-modal .sms-authentication__body").removeClass(["error", "loading", "success", "prompt"]);
        $(".sms-modal .sms-authentication__evaluation-result").html("");
    }
    loading() {
        this.clear();
        this.getSMSCodeInput().readonly(true);
        $(".sms-modal .sms-authentication__body").addClass("loading");
        $(".sms-modal .sms-authentication__evaluation-result").append('<span class="spinner"></span> processing');
    }
    success() {
        this.clear();
        $(".sms-modal .sms-authentication__body").addClass("success");
        $(".sms-modal .sms-authentication__evaluation-result").text("SUCCESS");
    }
    codeSent() {
        this.clear();
        $(".sms-modal .sms-authentication__body").addClass("prompt");
        $(".sms-modal .panel1 .sms-authentication__evaluation-result").text("Code Sent");
    }
    enablePanel2() {
        $(".sms-modal .sms-authentication__form-button-menu #resendCode").prop("disabled", true);
        $(".sms-modal .sms-authentication__form-button-menu #validate").prop("disabled", true);
        ConnectWise.GlobalModules.Effects.CssTimeSequence($(".sms-modal .slide"), {
            initialState: { add: "show-panel2-start", remove: "show-panel1" },
            duration: 300,
            transition: { add: "show-panel2-transition", remove: "show-panel2-start" },
            endState: { add: "show-panel2", remove: "show-panel2-transition" },
            callback: () => { $(".sms-modal #countryCode").focus(); }
        });
    }
    enablePanel1() {
        $(".sms-modal .sms-authentication__form-button-menu button").prop("disabled", false);
        $(".sms-modal #smsCode").focus();
        ConnectWise.GlobalModules.Effects.CssTimeSequence($(".sms-modal .slide"), {
            initialState: { add: "show-panel1-start", remove: "show-panel2" },
            duration: 300,
            transition: { add: "show-panel1-transition", remove: "show-panel1-start" },
            endState: { add: "show-panel1", remove: "show-panel1-transition" },
            callback: () => { }
        });
    }
    isNumber(text) {
        if (text) {
            var t = text.replace(/ /g, '');
            if (t.match(/^[0-9]+$/))
                return true;
            return false;
        }
        return false;
    }
    validatePhoneNumber() {
        this.clear();
        if (!this.isNumber($(".sms-modal #countryCode").val())) {
            this.setError("Invalid Country Code");
            return false;
        }
        else if (!this.isNumber($(".sms-modal #phoneNumber").val())) {
            this.setError("Invalid Phone. Only numbers and spaces allowed");
            return false;
        }
        return true;
    }
    sendSMS($form) {
        this.loading();
        let ph = this.getFormPhone($form);
        let contentId = $form.data("contentId");
        let CaptchaToken = $(".sms-modal .sms-captcha").data("token");
        if (!CaptchaToken && this.isCaptchaEnabled()) {
            this.setCaptchaError();
            return;
        }
        this.disableButton();
        $.ajax({
            type: "post",
            url: "/api/SMSAuth/send-sms-code",
            data: {
                CaptchaToken: CaptchaToken,
                Phone: ph,
                __RequestVerificationToken: $('[name="__RequestVerificationToken"]').val(),
                contentId
            },
        }).then((data) => {
            this.enableButton();
            this.codeSent();
            this.resetCaptcha();
            $(".sms-modal #sendCode").text("Resend Code");
            this.getSMSCodeInput().reset();
        }, (error) => {
            this.enableButton();
            if (error.responseText == "INVALID_CAPTCHA") {
                this.setCaptchaError();
                return;
            }
            this.setError(error.responseText);
            this.resetCaptcha();
        });
    }
    ;
    validateSMSCode($form, $modalTrigger, resolve) {
        let form = new FormEffects($form);
        this.loading();
        let data = {
            Phone: this.getFormPhone($form),
            __RequestVerificationToken: $('[name="__RequestVerificationToken"]').val(),
            Code: this.getSMSCodeInput().text(),
        };
        if (!data.Code) {
            this.setError("Code is Required");
            return;
        }
        $.ajax({
            type: "post",
            url: "/api/SMSAuth/validate-sms-code",
            data: Utils.serializeToFormData(data),
        }).then((r) => {
            let resp = r.result;
            if (resp == "SUCCESS") {
                this.success();
                form.setValidationToken(r.token);
                setTimeout(() => {
                    $modalTrigger.modaal("close");
                    resolve(true);
                }, 1000);
            }
            else {
                this.setError(resp === "CODE_EXPIRED"
                    ? "Code Expired, Request another one"
                    : "Invalid Code");
            }
        });
    }
    setModalEvents($form, $modalTrigger, resolve, reject) {
        //Modal Events
        $(".sms-modal  #validate").on("click", () => this.validateSMSCode($form, $modalTrigger, resolve));
        $(".sms-modal #smsCode").on("sms-code-input:complete", () => this.validateSMSCode($form, $modalTrigger, resolve));
        $(".sms-modal #cancel").on("click", () => {
            this.clear();
            $modalTrigger.modaal("close");
            this.enablePanel1();
            reject(false);
        });
        $(".sms-modal #resendCode").on("click", () => {
            this.clear();
            this.sendSMS($form);
        });
        $(".sms-modal #sendCode").on("click", () => {
            this.clear();
            $(".sms-modal .sms-authentication__form").slideDown("slow");
            this.sendSMS($form);
        });
        $(".sms-modal #changePhone").on("click", () => {
            this.clear();
            if (this.validatePhoneNumber()) {
                var cc = $(".sms-modal #countryCode").val().trim();
                let number = $(".sms-modal #phoneNumber").val().trim();
                let phone = `(+${cc}) ${number}`;
                this.setFormPhone($form, phone);
                this.enablePanel1();
            }
        });
        $(".sms-modal input").on("focusin", () => {
            this.clear();
        });
        $(".sms-modal a[data-action='change-phone']").on("click", () => {
            this.clear();
            this.enablePanel2();
        });
        $(".sms-modal .sms-authentication__form").on("click", () => {
            if (this.isError()) {
                this.clear();
                this.getSMSCodeInput().reset();
            }
        });
    }
}
