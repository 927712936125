export const desktopBreakpointMin = 1023.9999;
export function headerInit() {
    const header = document.getElementById('PageHeader');
    const footer = document.getElementById('PageFooter');
    const secondaryNav = document.querySelector('#secondary-nav');
    const menuButtons = document.getElementsByClassName('resp-tab-item hor-1');
    const hero = document.querySelector('[data-hero]');
    const main = document.querySelector('#PageMain');
    const utilNav = document.getElementById('utility-navigation');
    const mainNav = document.getElementById('main-navigation');
    const utilHeight = utilNav?.clientHeight ?? 0;
    const windowWidth = window.innerWidth;
    window.addEventListener('scroll', function () {
        if (!mainNav)
            return;
        const siteIconImageContainer = document.querySelector('.header-row-desktop__left .call-to-action-image');
        const siteIcon = siteIconImageContainer.firstElementChild.firstElementChild;
        if (windowWidth < desktopBreakpointMin && secondaryNav) {
            header.removeChild(secondaryNav);
            footer.appendChild(secondaryNav);
            secondaryNav.style.bottom = '0px';
            secondaryNav.style.position = 'fixed';
            header.style.transform = 'none';
            Array.from(menuButtons).forEach((button) => button.classList.remove('padding-small'));
        }
        else if (window.scrollY >= utilHeight && windowWidth >= desktopBreakpointMin) {
            header.style.transform = `translateY(-${utilHeight}px)`;
            Array.from(menuButtons).forEach((button) => button.classList.add('padding-small'));
            siteIcon && siteIcon.classList.add('width-small');
        }
        else if (window.scrollY < utilHeight) {
            header.style.transform = 'none';
            Array.from(menuButtons).forEach((button) => button.classList.remove('padding-small'));
            siteIcon && siteIcon.classList.remove('width-small');
        }
    });
    function attachSecondaryAtBottom() {
        if (windowWidth < desktopBreakpointMin && secondaryNav) {
            header.removeChild(secondaryNav);
            footer.appendChild(secondaryNav);
            secondaryNav.style.bottom = '0px';
            secondaryNav.style.position = 'fixed';
        }
    }
    function setMarginForContentAfterHeader() {
        if (!header || !mainNav || mainNav.classList.contains('disable-sticky-mode'))
            return;
        const headerHeight = header.clientHeight;
        //if (!hero && main) {
        //  main.style.marginTop = `${headerHeight}px`;
        //} else if (hero) {
        //  hero.style.marginTop = `${headerHeight}px`;
        //}
    }
    window.addEventListener('load', setMarginForContentAfterHeader);
    window.addEventListener('resize', setMarginForContentAfterHeader);
    attachSecondaryAtBottom();
}
