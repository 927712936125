﻿module.exports = function (form) {
    var $form = $(form.getFormElem());
    var submitOverrideAction = $form.data('submit-override-action');
    if(!submitOverrideAction || submitOverrideAction == '')
    {
        form.submit();
        return;
    }

    var formData = form.getValues();
    formData.__RequestVerificationToken = $('[name="__RequestVerificationToken"]').val();
    formData._webinarID = $('[name="_webinarID"]').val()
    if (form.validate()) {
        ConnectWise.GlobalModules.Marketo.Effects.lockForm($form);
        $.ajax({
            type: 'POST',
            url: submitOverrideAction,
            data: formData,
            complete: function () {
                ConnectWise.GlobalModules.Marketo.Effects.unlockForm($form)
            },
            success: function (result) {
                switch (result.status) {
                    case "SUCCESS":
                        form.submit();
                        break;
                    case "ALREADY_REGISTERED":                        
                        console.log("You already registered for this webinar");
                        //ConnectWise.Utils.ErrorModal("You already registered for this webinar");
                        break;
                    default:
                        console.log("We could not process your registration. Please try again later.");
                        //ConnectWise.Utils.ErrorModal("We could not process your registration. Please try again later.");
                        break;
                }
            },
            error: function (result) {
                console.log("We could not process your registration. Please try again later.");
                //ConnectWise.Utils.ErrorModal("We could not process your registration. Please try again later.")
            }
        })
    }


};