import doSearchContent from './doSearchContent';
export const isCertifiedToggleOn = (elements) => {
    return elements.certifiedToggle?.checked ?? false;
};
const initCertifiedToggle = (elements, searchSettings) => {
    if (elements.certifiedToggle != null) {
        elements.certifiedToggle.addEventListener('change', () => {
            doSearchContent(1, elements, searchSettings);
        });
    }
};
export default initCertifiedToggle;
