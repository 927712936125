import $ from 'jquery';
export function modalInit() {
    const modalButtons = document.querySelectorAll('.js-modal-button');
    modalButtons.forEach((element) => {
        element.addEventListener('click', (event) => {
            const modalId = element.getAttribute('data-modal-id');
            const modalTemplate = document.getElementById(`${modalId}-modal-template`);
            if (modalTemplate == null)
                return;
            let $modalContainer = $(modalTemplate.children.item(0));
            if ($modalContainer.length) {
                const $closeModalButton = $modalContainer.find('.js-close-button');
                if ($closeModalButton.length) {
                    $closeModalButton.on('click', (event) => {
                        $modalContainer.appendTo($(modalTemplate));
                    });
                }
                $modalContainer.appendTo($(document.body));
            }
        });
    });
}
